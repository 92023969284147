import { invokeApi } from "../../bl_libs/invokeApi";

export const _admin_login = async (data) => {
  let requestObj = {
    path: "api/app_api/login",
    method: "POST",
    headers: {
      // "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _general = async () => {
  let requestObj = {
    path: `api/general_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _project_init_with_token = async () => {
  let requestObj = {
    path: `api/app_api/login_by_token`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_settings = async (data) => {
  let requestObj = {
    path: `api/general_setting/edit_general_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_dashboard = async () => {
  let requestObj = {
    path: `api/init/admin_dashboard`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _app_api_change_password = async (data) => {
  let requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};