import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
// import CustomMUITable from "../../components/GeneralComponents/CustomMUITable";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _get_all_games } from "../../DAL/game/game";
import { useSnackbar } from "notistack";
import {
  _get_all_league_listing,
  _get_all_main_leagues,
} from "../../DAL/leagues/leagues";
import { _get_all_teams } from "../../DAL/teams/teams";
import { _delete_player, _get_all_players } from "../../DAL/players/players";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { countries, s3baseUrl } from "../../config/config";

import {
  Button,
  TextField,
  Accordion,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  CircularProgress,
} from "@mui/material";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "name", label: "NAME", alignRight: false },
  { id: "image", label: "IMAGE", alignRight: false, type: "thumbnail" },
  { id: "game_title", label: "SPORT", alignRight: false },
  { id: "country", label: "COUNTRY", alignRight: false },
  { id: "status", label: "STATUS", alignRight: false, type: "row_status" },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const PlayersListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allPlayers, setAllPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allGames, setAllGames] = useState([]);
  const [allLeagues, setAllLeagues] = useState([]);
  const [allTeams, setAllTeams] = useState([]);

  const [inputs, setInputs] = useState({
    league_name: "",
    game_id: "AllSports",
    country: "AllCountries",
    team: "AllTeams",
    league: "AllLeagues",
    search: "",
    status: true,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleEdit = (value) => {
    navigate(`/players/edit-player/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDrawer = () => {
    // setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
    navigate(`/players/add-player`);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const getAllLeagues = async () => {
    setIsLoading(true);
    const response = await _get_all_main_leagues();

    if (response.code === 200) {
      const LeagueData = response.leagues.map((league, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + league.image,
          alt: league.image,
        };

        return {
          ...league,
          image: profileImage,
        };
      });

      setAllLeagues(LeagueData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }

  };
  const getAllTeams = async () => {
    // setIsLoading(true);
    const response = await _get_all_teams({
      search: "",
    });
    if (response.code === 200) {
      const GameData = response.teams.map((game, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,

          game_title: game.game_id.title,
        };
      });

      setAllTeams(GameData);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }

  };

  const getAllGames = async () => {
    // setIsLoading(true);
    const response = await _get_all_games();

    if (response.code === 200) {
      const GameData = response.games.map((game, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,
        };
      });

      setAllGames(GameData);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }

  };

  const getAllFilterPlayers = async () => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("filter_status", true);
    formData.append("search", inputs.search);

    if (inputs.country == "AllCountries") {
      formData.append("country", "");
    } else {
      formData.append("country", inputs.country);
    }

    if (inputs.game_id == "AllSports") {
      formData.append("game", "");
    } else {
      formData.append("game", inputs.game_id);
    }

    if (inputs.team == "AllTeams") {
      formData.append("team", "");
    } else {
      formData.append("team", inputs.team);
    }

    // if (inputs.league == "AllLeagues") {
    //   formData.append("division", "");
    // } else {
    //   formData.append("division", inputs.league);
    // }

    const response = await _get_all_players(formData);

    if (response.code === 200) {
      const GameData = response.players.map((player, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + player.image,
          alt: player.image,
        };

        return {
          ...player,
          image: profileImage,
          name: `${player.first_name} ${player.last_name}`,
          game_title: player.game.title,
        };
      });

      setAllPlayers(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }

  };

  const getAllPlayers = async () => {
    setIsLoading(true);
    const response = await _get_all_players({
      search: "",
    });
    if (response.code === 200) {
      const GameData = response.players.map((player, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + player.image,
          alt: player.image,
        };

        return {
          ...player,
          image: profileImage,
          name: `${player.first_name} ${player.last_name}`,
          game_title: player.game.title,
        };
      });

      setAllPlayers(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_player(deleteValue);
    if (result.code === 200) {
      let new_players_list = allPlayers.filter((player) => {
        return player._id !== deleteValue;
      });

      setAllPlayers(new_players_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFilter = () => {
    getAllFilterPlayers();
  };
  const handleFilterReset = () => {
    setInputs((Inputs) => ({
      ...Inputs,
      game_id: "AllSports",
      team: "AllTeams",
      league: "AllLeagues",
      country: "AllCountries",
      search: "",
      status: true,
    }));
    getAllPlayers();
  };

  useEffect(() => {
    // setIsLoading(true);
    getAllPlayers();
    getAllGames();
    getAllTeams();
    // getAllLeagues();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Player?"}
        handleAgree={handleDelete}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Players"
      //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <div className="row">
            <div className="col-md-3 col-xs-12 mb-2">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Sports</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="game_id"
                  value={inputs.game_id}
                  label="Sports"
                  onChange={handleChange}
                  defaultValue="AllSports" // Added defaultValue prop with an empty string
                >
                  <MenuItem key="AllSports" value="AllSports" selected>
                    All Sports
                  </MenuItem>

                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.title.charAt(0).toUpperCase() + game.title.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-3 col-xs-12 mb-2">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="team"
                  value={inputs.team}
                  label="Teams"
                  onChange={handleChange}
                  defaultValue="AllTeams" // Added defaultValue prop with an empty string
                >
                  <MenuItem key="AllTeams" value="AllTeams" selected>
                    All Teams
                  </MenuItem>

                  {allTeams.map((team) => (
                    <MenuItem key={team._id} value={team._id}>
                      {team.name.charAt(0).toUpperCase() + team.name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-md-3 col-xs-12 mb-2">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Leagues</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="league"
                  value={inputs.league}
                  label="Leagues"
                  onChange={handleChange}
                  defaultValue="AllLeagues" // Added defaultValue prop with an empty string
                >
                  <MenuItem key="Leagues" value="AllLeagues" selected>
                    All Leagues
                  </MenuItem>

                  {allLeagues.map((league) => (
                    <MenuItem key={league._id} value={league._id}>
                      {league.league_name.charAt(0).toUpperCase() +
                        league.league_name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            <div className="col-md-3 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="country"
                  value={inputs.country} // Set the default value using the value prop
                  label="Country"
                  onChange={handleChange}
                >
                  <MenuItem key="AllCountries" value="AllCountries" selected>
                    All Countries
                  </MenuItem>

                  {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country.charAt(0).toUpperCase() + country.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 mt-3 text-end">
          <div className=" ">
            <button className="normal-contained-button" onClick={handleFilter}>
              Filter
            </button>
            <button
              className="normal-contained-button"
              onClick={handleFilterReset}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Players</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add New Player
          </button>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allPlayers}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default PlayersListing;
