import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import {
  _delete_Main_league,
  _get_all_main_leagues,
} from "../../DAL/leagues/leagues";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "league_name", label: "LEAGUES", alignRight: false },
  { id: "image", label: "IMAGE", alignRight: false, type: "thumbnail" },
  { id: "status", label: "STATUS", alignRight: false, type: "row_status" },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const MainLeagueListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allLeagues, setAllLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleEdit = (value) => {
    navigate(`/leagues/edit-main-league/${value._id}`, {
      state: value,
    });
  };

  const handleLeagues = (value) => {
    console.log('value',value);
    navigate(
      `/leagues/league-listing/${value._id}?club_id=${value.club._id}&league_name=${value.league_name}&game_id=${value.game_id._id}`,
      {
        state: value,
      }
    );
  };

  const handleAssignAdmin = (value) => {
    navigate(`/leagues/assign-club/${value._id}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
    navigate(`/leagues/add-main-league`);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    {
      label: "Leagues Division",
      icon: "fluent:sport-soccer-24-filled",
      handleClick: handleLeagues,
    },
    // {
    //   label: "Assign to Club",
    //   icon: "ri:admin-line",
    //   handleClick: handleAssignAdmin,
    // },
  ];

  const getAllLeagues = async () => {
    setIsLoading(true);
    const response = await _get_all_main_leagues();
    if (response.code === 200) {
      const LeagueData = response.leagues.map((league, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + league.image,
          alt: league.image,
        };

        return {
          ...league,
          image: profileImage,
        };
      });

      setAllLeagues(LeagueData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_Main_league(deleteValue);
    if (result.code === 200) {
      let new_leagues_list = allLeagues.filter((league) => {
        return league._id !== deleteValue;
      });
      setAllLeagues(new_leagues_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllLeagues();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this League?"}
        cautionaryText="⚠️ If you want to delete this League, make sure the league contains no divisional match that has started."
        handleAgree={handleDelete}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Leagues"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Leagues</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add League
          </button>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allLeagues}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default MainLeagueListing;
