import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers";
import {
  _add_match,
  _teams_listing_by_division_id,
} from "../../DAL/leagues/leagues";

function AddDivisionMatch() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [allTeams, setAllTeams] = useState([]);
  const param = useParams();
  const _ID = param.id;

  const [inputs, setInputs] = useState({
    division_id: _ID,
    teamA_id: "",
    teamB_id: "",
    // date: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleHomeTeamChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    allTeams.map((Team) => {
      const teamId = Team.team._id;
      const address = Team.team.address;

      if (
        teamId === value
        // && (inputs.location =="")
      ) {
        setInputs((values) => ({
          ...values,
          [name]: value,
          location: address,
        }));
      } else {
        setInputs((values) => ({ ...values, [name]: value }));
      }
    });
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setInputs((values) => ({
      ...values,
      date: dayjs(date),
      format_date: formattedDate,
    }));
  };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const TestDateChange = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth) ||
      (selectedYear === currentYear &&
        selectedMonth === currentMonth &&
        selectedDay >= currentDay)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("division_id", inputs.division_id);
    formData.append("teamA_id", inputs.teamA_id);
    formData.append("teamB_id", inputs.teamB_id);
    formData.append("date", inputs.format_date);
    formData.append("time", inputs.format_time);
    formData.append("location", inputs.location);
    formData.append("status", inputs.status);

    if (inputs.teamA_id === "") {
      error = "Please! Select Team 1";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.teamB_id === "") {
      error = "Please! Select Team 2";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.teamA_id === inputs.teamB_id) {
      error = "Please! Select Different teams";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_date === "") {
      error = "Please! Select a date";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_date === "Invalid Date") {
      error = "Invalid Date!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (TestDateChange(inputs.format_date) === false) {
      error = "Please select today's date or a date in the future!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_time === "") {
      error = "Please! Select a Time";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_time === "Invalid Date") {
      error = "Invalid Time!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.location.trim() === "") {
      error = "Please! Select a Venue";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      if (error === "") {
        setIsLoading(true);
        const result = await _add_match(formData);

        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const getTeams = async (id) => {
    setIsLoading(true);
    const response = await _teams_listing_by_division_id(id);

    if (response.code === 200) {
      setAllTeams(response.teams_of_division);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getTeams(_ID);
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Match</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Team 1*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="teamA_id"
                  value={inputs.teamA_id}
                  required
                  label="Team 1*"
                  onChange={handleHomeTeamChange}
                >
                  {allTeams.map((team) => (
                    <MenuItem key={team.team._id} value={team.team._id}>
                      {team.team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Team 2*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="teamB_id"
                  value={inputs.teamB_id}
                  required
                  label="Team 2*"
                  onChange={handleChange}
                >
                  {allTeams.map((team) => (
                    <MenuItem key={team.team._id} value={team.team._id}>
                      {team.team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Match Date *"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date"
                  value={inputs?.date}
                  onChange={handleDateChange}
                  minDate={dayjs()} // Set the minimum selectable date to the current date
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Match Time *"
                  className="form-control"
                  name="time"
                  value={inputs?.time}
                  onChange={handleTimeChange}
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Match Venue "
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="location"
                value={inputs?.location}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddDivisionMatch;
