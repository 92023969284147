import React from "react";
import { SnackbarProvider } from "notistack";
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slide, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// routes
import Routers from "./routes";
import { ContentSettingState } from "./Hooks/ContentContext/ContentSettingState";

export default function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <Button
          className="snackbar-cross-icon"
          onClick={onClickDismiss(key)}
          style={{ color: "white" }} // Change the color here
        >
          <CloseIcon />
        </Button>
      )}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      TransitionComponent={Slide}
      maxSnack={3}
    >
      <ContentSettingState>
        <Routers />
      </ContentSettingState>
    </SnackbarProvider>
  );
}
