import React, { useEffect, useState } from "react";
// material
import { Box, CircularProgress } from "@mui/material";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { Icon } from "@iconify/react";
import { _get_all_games } from "../../DAL/game/game";
import { _get_all_main_leagues } from "../../DAL/leagues/leagues";
import { _get_all_teams } from "../../DAL/teams/teams";
import { _get_all_players } from "../../DAL/players/players";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _all_app_admins } from "../../DAL/admin/admin";
import { useSnackbar } from "notistack";

const TABLE_HEAD_ALL_ADMINS = [
  { id: "first_name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];
const TABLE_HEAD_ALL_LEAGUES = [
  { id: "league_name", label: "Name", alignRight: false },
  // { id: "game_id.title", label: "Sport", alignRight: false },
  { id: "game_title", label: "Sport", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const getIcon = (name) => <Icon icon={name} width={25} height={25} />;

export default function Dashboard() {
  const [allAdmins, setAllAdmins] = useState([]);
  const [allLeague, setAllLeague] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const getDashboard = async () => {
    setIsLoading(true);
    const gameListingResponse = await _get_all_games();

    if (gameListingResponse.code === 200) {
      setIsLoading(false);
    } else {
      enqueueSnackbar(gameListingResponse.message, { variant: "error" });
      setIsLoading(false);
    }

    setIsLoading(true);
    const leagueListingResponse = await _get_all_main_leagues();
    if (leagueListingResponse.code === 200) {
      const LeagueData = leagueListingResponse.leagues.map((league, index) => {
        return {
          ...league,

          game_title: league.game_id.title,
        };
      });

      setAllLeague(LeagueData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(leagueListingResponse.message, { variant: "error" });
      setIsLoading(false);
    }

    setIsLoading(true);
    const teamsListingResponse = await _get_all_teams({
      search: "",
    });
    if (teamsListingResponse.code === 200) {
      setIsLoading(false);
    } else {
      enqueueSnackbar(teamsListingResponse.message, { variant: "error" });
      setIsLoading(false);
    }
    setIsLoading(true);
    const playersListingResponse = await _get_all_players({
      search: "",
    });
    if (playersListingResponse.code === 200) {
      setIsLoading(false);
    } else {
      enqueueSnackbar(playersListingResponse.message, { variant: "error" });
      setIsLoading(false);
    }

    // setIsLoading(true);
    // const allAppAdminsResponse = await _all_app_admins({
    //   approval_status: true,
    // });
    // if (allAppAdminsResponse.code === 200) {
    //   setAllAdmins(allAppAdminsResponse.Admins);
    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(allAppAdminsResponse.message, { variant: "error" });
    //   setIsLoading(false);
    // }

    setCards((cards) => [
      {
        ...cards[0],
        counting: gameListingResponse.count,
      },
      {
        ...cards[1],
        counting: leagueListingResponse.count,
      },
      {
        ...cards[2],
        counting: teamsListingResponse.count,
      },
      {
        ...cards[3],
        counting: playersListingResponse.count,
      },
    ]);
  };

  useEffect(() => {
    // setIsLoading(true);
    getDashboard();
  }, []);

  const [cards, setCards] = useState([
    {
      icon: getIcon("fluent:sport-american-football-24-filled"),
      counting: 0,
      title: "Sports",
      className: "total-products",
    },
    {
      icon: getIcon("fluent:sport-soccer-24-filled"),
      counting: 0,
      title: "Leagues",
      className: "total-orders",
    },
    {
      icon: getIcon("ri:team-fill"),
      counting: 0,
      title: "Teams",
      className: "total-shipments",
    },
    {
      icon: getIcon("ant-design:team-outlined"),
      counting: 0,
      title: "Players",
      className: "total-employees",
    },
  ]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  return (
    <div className="container">
      <Box sx={{ pb: 2, mt: 2 }}>
        <h3>Hi, Welcome back</h3>
      </Box>
      <DashboardCard cards={cards} />
      <div className="row">
        {allAdmins.length > 0 ? (
          // <div className="col-12 col-md-12 mt-4">
          //   <h3 className="pb-2">Recent Admin User</h3>
          //   <CustomMUITable
          //     TABLE_HEAD={TABLE_HEAD_ALL_ADMINS}
          //     data={allAdmins.slice(0, 3)}
          //     hide_search={true}
          //     hide_pagination={true}
          //   />
          // </div>
          ""
        ) : (
          ""
        )}
        {allLeague.length > 0 ? (
          <div className="col-12 col-md-12 mt-4">
            <h3 className="pb-2">Recent Leagues</h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_ALL_LEAGUES}
              data={allLeague.slice(0, 3)}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
