import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  IconButton,
  Card,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Badge,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { avatar_base_url, s3baseUrl } from "../../config/config";
import {
  _get_team_listing_by_clubs,
  _get_teams_by_game_id,
} from "../../DAL/teams/teams";
import { _add_division } from "../../DAL/leagues/leagues";
import { _club_active_managers, _get_list_club } from "../../DAL/clubs/clubs";

const Input = styled("input")({
  display: "none",
});
function AddLeague() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allTeams, setAllTeams] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  let league_id = "";
  let club_id = "";
  let game_id = "game_id";

  if (urlParams.get("league_id") != null) {
    league_id = urlParams.get("league_id");
  }
  if (urlParams.get("game_id") != null) {
    game_id = urlParams.get("game_id");
  }
  if (urlParams.get("club_id") != null && urlParams.get("club_id") !== "") {
    club_id = urlParams.get("club_id");
  } else {
    navigate("/leagues/");
  }

  const [inputs, setInputs] = useState({
    division_name: "",
    teams: [],
    game_id: "",
    start_date: "",
    end_date: "",
    club: [],
    club_id: [],
    status: true,
    dob: "",
    manager_id: [],
  });

  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClubChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
      // manager_id: [],
      teams: [],
    }));
    // getAllAdmins(value);
  };

  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, start_date: formattedDate }));
  };
  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, end_date: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("division_name", inputs.division_name);
    formData.append("teams", JSON.stringify(inputs.teams));
    formData.append("start_date", inputs.start_date);
    formData.append("end_date", inputs.end_date);
    // formData.append("division_type", "division_type");
    formData.append("league_id", league_id);
    // formData.append("clubs", inputs.club_id);

    formData.append("clubs", JSON.stringify(inputs.club_id));
    formData.append("managers", JSON.stringify(inputs.manager_id));

    if (inputs.division_name.trim() === "") {
      error = "Please enter the Division Name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (game_id.trim() === "") {
      error = "Game ID is required";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.start_date === "") {
      error = "Please! Enter Start Date";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.end_date === "") {
      error = "Please! Enter End Date";
      enqueueSnackbar(error, { variant: "error" });
    } else if (league_id.trim() === "") {
      error = "league_id is required!";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      if (image && error === "") {
        if (image.type.startsWith("image/")) {
          formData.append("image", image);
          setIsLoading(true);
          const result = await _add_division(formData);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
      }
    }
  };

  const getAllClubs = async () => {
    const response = await _get_list_club({
      // "text":"zzz",
      approval_status: true,
    });

    console.log("response _get_list_club", response);

    if (response.code === 200) {
      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };

        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string

        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
        };
      });

      setAllClubs(ClubData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTeams = async (ids) => {
    setIsLoading(true);
    const response = await _get_team_listing_by_clubs({
      clubs: ids,
    });

    console.log("response _get_team_listing_by_clubs", response);
    if (response.code === 200) {
      setAllTeams(response.teams);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleClose = () => {
    // This function will be called when the Select menu is closed
    // after selecting an option or clicking outside of it.
    console.log("Select menu closed");
    console.log("inputs", inputs);
    // Your additional logic or function call can be placed here.
    if (inputs.club_id.length > 0) {
      getTeams(inputs.club_id);
    }
  };
  const getAllAdmins = async (CLUB_ID) => {
    setIsLoading(true);
    // const response = await _club_managers(CLUB_ID);
    const response = await _club_active_managers(CLUB_ID);
    console.log('response _club_managers',response);

    if (response.code === 200) {

      // const filteredAdmins = response.managers.filter((item) => item.status === true);
      // const AdminData = filteredAdmins.map((Admin, index) => {

      const AdminData = response.managers.map((Admin, index) => {
        let profileImage = {};
        // let dateStr = Admin.createdAt;
        // let date = new Date(dateStr);
        // let day = date.getDate();
        // let month = date.getMonth() + 1; // Adding 1 to get the correct month (January is 0-based)
        // let year = date.getFullYear();
        // let formattedDate = `${day < 10 ? "0" + day : day}-${
        //   month < 10 ? "0" + month : month
        // }-${year}`;

        if (Admin.profile_image === "picture") {
          profileImage = {
            src: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
            alt: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
          };
        } else {
          profileImage = {
            src: s3baseUrl + Admin.profile_image,
            alt: Admin.profile_image,
          };
        }

        return {
          ...Admin,
          profile_image: profileImage,
        //   createdAt: formattedDate,
        };
      });

      setAllAdmins(AdminData);
      setIsLoading(false);
      console.log('ALL Managers  _club_managers',AdminData);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // getTeams(game_id);
    getAllClubs();
    getAllAdmins(club_id);
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Division</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Division Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="division_name"
                value={inputs?.division_name}
                onChange={handleChange}
              />
            </div>
            {/* 
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Sports*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="game_id"
                  value={inputs.game_id}
                  required
                  label="Sports"
                  onChange={handleChangeGame}
                >
                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.title.charAt(0).toUpperCase() + game.title.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            {/* <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Clubs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="club"
                  value={inputs.club}
                  // required
                  label="Clubs"
                  onChange={handleChange}
                  multiple // Enable multi-selection
                >
                  <MenuItem value="ClubA">Club A</MenuItem>
                  <MenuItem value="ClubB">Club B</MenuItem>
                  <MenuItem value="HaseebClub">Haseeb Club</MenuItem>
                  <MenuItem value="PakistanClub">Pakistan Club</MenuItem>
                </Select>
              </FormControl>
            </div> */}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Clubs*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="club_id"
                  value={inputs.club_id}
                  required
                  label="Clubs"
                  onChange={handleClubChange}
                  // onChange={handleChange}
                  onClose={handleClose} // Call the handleClose function when the Select menu closes
                  multiple // Enable multi-selection
                >
                  {allClubs.map((club) => (
                    <MenuItem key={club._id} value={club._id}>
                      {club.club_name.charAt(0).toUpperCase() +
                        club.club_name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Teams*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="teams"
                  value={inputs.teams}
                  required
                  label="Teams"
                  multiple // Enable multi-selection
                  onChange={handleChange}
                >
                  {allTeams.map((team) => (
                    <MenuItem key={team._id} value={team._id}>
                      {team.name} &nbsp; ( {team.club_id.club_name} ) &nbsp;
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date*"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date_of_birth"
                  value={inputs?.date_of_birth}
                  onChange={handleStartDateChange}
                  minDate={dayjs()} // Set the minimum selectable date to the current date
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date *"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date_of_birth"
                  value={inputs?.date_of_birth}
                  onChange={handleEndDateChange}
                  minDate={dayjs()} // Set the minimum selectable date to the current date
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Managers / Optional
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="manager_id"
                  value={inputs.manager_id}
                  label="Managers / Optional"
                  onChange={handleChange}
                  multiple // Enable multi-selection
                >
                  {allAdmins.map((manager) => (
                    <MenuItem key={manager.user_id} value={manager.user_id}>
                      {manager.first_name.charAt(0).toUpperCase() +
                        manager.first_name.slice(1) +
                        " " +
                        manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddLeague;
