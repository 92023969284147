import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _add_admin,
  _detail_app_admin,
  _update_app_admin,
} from "../../DAL/admin/admin";
import { avatar_base_url, s3baseUrl } from "../../config/config";

const Input = styled("input")({
  display: "none",
});
function EditManager() {
  const navigate = useNavigate();
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    status: true,
    address: "",
    email: "",
    password: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handle_format_data = (data) => {
    setInputs({
      ...data,
    });
    setPreviews(data.profile_image.src);
  };

  const get_detail_app_admin = async () => {
    setIsLoading(true);
    const response = await _detail_app_admin(_ID);

    console.log("_detail_app_admin    ", response);

    if (response.code === 200) {
      let AdminData = response.manager;
      let newAdmin = {
        ...AdminData,
        profile_image: {
          src: s3baseUrl + AdminData.profile_image,
          alt: AdminData.profile_image,
        },
      };

      handle_format_data(newAdmin);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("_ID", _ID);
    console.log("inputs", inputs);

    let error = "";
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("status", inputs.status);

    formData.append("email", inputs.email);

    if (image) {
      if (image.type.startsWith("image/")) {
        formData.append("profile_image", image);
      }
    }

    if (inputs.first_name.trim() === "") {
      error = "Please enter the First Name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.last_name.trim() === "") {
      error = "Please enter the Last Name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.email.trim() === "") {
      error = "Please enter the Email";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      setIsLoading(true);

      const result = await _update_app_admin(formData, inputs.user_id);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log("state", state);
    if (!state) {
      get_detail_app_admin();
    } else {
      handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Manager</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
                inputProps={{
                  pattern: "^[A-Za-z ]+$",
                  title: "Please enter alphabetic characters only.",
                }}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
                inputProps={{
                  pattern: "^[A-Za-z ]+$",
                  title: "Please enter alphabetic characters only.",
                }}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditManager;
