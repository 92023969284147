import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  IconButton,
  Card,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _reset_password,
} from "../../DAL/admin/admin";

function ChangeClubPassword() {
  const navigate = useNavigate();
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

 
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    email: _ID,
    password: "",
    confirm_password: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handle_format_data = (data) => {
    setInputs({
      ...data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();

    formData.append("email", inputs.email);
    formData.append("password", inputs.password);
    formData.append("confirm_password", inputs.confirm_password);

    if (inputs.email.trim() === "") {
      error = "Email is required";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.password.trim() === "") {
      error = "Please! Enter Password";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.confirm_password.trim() === "") {
      error = "Please! Enter confirm Password";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.password !== inputs.confirm_password) {
      error = "Password and Confirm Password Must be same";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      setIsLoading(true);
      const result = await _reset_password(formData);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (!state) {
    //   //   get_detail_product();
    // } else {

    //   handle_format_data(state);
    //   //   window.history.replaceState({}, document.title);
    // }
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Change Password</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
                disabled={true}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_4"
                label="Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_5"
                label="Confirm Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="confirm_password"
                value={inputs?.confirm_password}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default ChangeClubPassword;
