import React, { useState } from "react";
import PinField from "react-pin-field";
// import { useSnackbar } from "notistack";
// material
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import { Container } from "react-bootstrap";
// import { LoadingButton } from "@mui/lab";

export default function PincodeVerification({
  onhandlePinCodeSubmit,
  setIsLoading,
  isLoading,
}) {
  // const { enqueueSnackbar } = useSnackbar();
  const [pinCode, setPinCode] = useState("");

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (pinCode.length > 5) {
      onhandlePinCodeSubmit(pinCode);
    } else {
      alert("All fields should be filled");
      setIsLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Container>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          fullWidth>
          <PinField
            onChange={setPinCode}
            type="numeric"
            inputMode="number"
            validate={/^[0-9]$/}
            style={{
              caretColor: "transparent",
              width: 50,
              height: 50,
              outline: "none",
              textAlign: "center",
              borderColor: "#198BA8",
            }}
            // inputstyle={{ borderColor: "#198BA8" }}
            // inputfocusstyle={{ borderColor: "blue" }}
            length={6}
          />
        </Stack>
      </Container>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}>
        <Button fullWidth size="large" type="submit" variant="contained">
          Submit
        </Button>
      </Stack>
    </form>
  );
}
