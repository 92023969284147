import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers";
import { _detail_match, _update_match } from "../../DAL/leagues/leagues";

function EditDivisionMatch() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setInputs((values) => ({
      ...values,
      date: dayjs(date),
      format_date: formattedDate,
    }));
  };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handle_format_data = (data) => {
    const data_date = data.date;
    const [day, month, year] = data_date.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    const data_time = data.time;
    const [time, meridiem] = data_time.split(" ");
    const [hours, minutes] = time.split(":");
    let formattedHours = parseInt(hours);
    if (meridiem === "PM") {
      formattedHours += 12;
    }
    const formattedTime = `${formattedHours}:${minutes}`;

    setInputs({
      ...inputs,

      division_id: data.division_id._id,
      location: data.location,
      status: data.status,
      data_time: data.time, //"12:10 PM"
      data_date: data.date, //"25/05/2023"

      date1: dayjs(data.date, "DD/MM/YYYY").toDate(),
      date: dayjs(new Date(dayjs(data.date, "DD/MM/YYYY").toDate())),

      time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),

      format_time: formattedTime,
      format_date: formattedDate,
    });
  };

  const get_detail_match = async () => {
    setIsLoading(true);
    const response = await _detail_match(_ID);

    if (response.code === 200) {
      let MatchData = response.match;

      const dateString = MatchData.date;
      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString("en-GB"); // Adjust the locale based on your desired date format

      const timeString = MatchData.time;
      const [hours, minutes] = timeString.split(":");
      const timeObj = new Date();
      timeObj.setHours(hours);
      timeObj.setMinutes(minutes);
      const formattedTime = timeObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      let newMatchData = {
        ...MatchData,

        date: formattedDate,
        time: formattedTime,
      };

      handle_format_data(newMatchData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const testValidDateChange = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth) ||
      (selectedYear === currentYear &&
        selectedMonth === currentMonth &&
        selectedDay >= currentDay)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";
    const formData = new FormData();

    formData.append("date", inputs.format_date);
    formData.append("time", inputs.format_time);
    formData.append("location", inputs.location);
    formData.append("status", inputs.status);

    if (inputs.format_date === "") {
      error = "Please! Select a date";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_date === "Invalid Date") {
      error = "Invalid Date!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (testValidDateChange(inputs.format_date) === false) {
      error = "Please select today's date or a date in the future!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_time === "") {
      error = "Please! Select a Time";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.format_time === "Invalid Date") {
      error = "Invalid Time!";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.location.trim() === "") {
      error = "Please! Select a Venue";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      if (error === "") {
        setIsLoading(true);
        const result = await _update_match(formData, _ID);

        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!state) {
      get_detail_match();
    } else {
      handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Match</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Match Date *"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date"
                  value={inputs?.date}
                  onChange={handleDateChange}
                  minDate={dayjs()} // Set the minimum selectable date to the current date
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Match Time *"
                  className="form-control"
                  name="time"
                  value={inputs?.time}
                  onChange={handleTimeChange}
                />
              </LocalizationProvider>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Match Venue "
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="location"
                value={inputs?.location}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditDivisionMatch;
