import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_all_games= async () => {
  let requestObj = {
    path: `api/game/all_games`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
     },
  };
  return invokeApi(requestObj);
};
export const _get_active_games= async () => {
  let requestObj = {
    path: `api/game/get_active_games`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_game = async (data) => {
 
  const requestObj = {
    path: `api/game/add_game`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_game= async (id) => {
  const requestObj = {
    path: `api/game/delete_game/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
  };
  return invokeApi(requestObj);
};
export const _update_game = async (postData, _id) => {
  const requestObj = {
    path: `api/game/update_game/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};


export const _detail_game= async (_id) => {
  let requestObj = {
    path: `api/game/detail_game/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};