import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { styled } from "@mui/material/styles";
import { _project_init_with_token } from "../../DAL/login/login";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { _set_user_to_localStorage } from "../../DAL/localStorage/localStorage";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;
const drawerWidth = 250;

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function DashboardLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleUserInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleInit = async () => {
    // setIsLoading(true);
    // const response = await _project_init_with_token();
    // if (response.code === 200) {
    //   handleUserInfo(response.user);
    //   _set_user_to_localStorage(response.user);
    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(response.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  const athenciate = () => {
    if (!localStorage.getItem("token")) {
      navigate(`/login`);
       setIsLoading(false);
    } 
    // else {
    //   handleInit();
    // }
  };

  useEffect(() => {
    athenciate();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="layout-index">
      <DashboardNavbar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <DashboardSidebar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </div>
  );
}
