import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "@material-ui/core";
import {
  _delete_match,
  _get_match_listing_by_division_id,
} from "../../DAL/leagues/leagues";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "teamA", label: "HOME TEAM", alignRight: false },
  { id: "vs", label: "VS", alignRight: false },
  { id: "teamB", label: "AWAY TEAM", alignRight: false },
  { id: "time", label: "TIME", alignRight: false },
  { id: "date", label: "DATE", alignRight: false },
  { id: "location", label: "VENUE", alignRight: false },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const MatchListing = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);

  const [todayMatches, setTodayMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [previousMatches, setPreviousMatches] = useState([]);

  const [value, setValue] = React.useState("1");

  const param = useParams();
  const _ID = param.id;
  const urlParams = new URLSearchParams(window.location.search);

  let division_name = "Division";
  let league_name = "League";
  let league_id = "";
  let gameId = "";

  if (urlParams.get("league_name") != null) {
    league_name = urlParams.get("league_name");
  }
  if (urlParams.get("division_name") != null) {
    division_name = urlParams.get("division_name");
  }

  if (urlParams.get("game_id") != null && urlParams.get("game_id") !== "") {
    gameId = urlParams.get("game_id");
  } else {
    navigate("/leagues/");
  }

  if (urlParams.get("league_id") != null && urlParams.get("league_id") !== "") {
    league_id = urlParams.get("league_id");
  } else {
    navigate("/leagues/");
  }

  const handleClickBreadcrumbs = (event) => {
    event.preventDefault();

    const clickedElement = event.target.textContent;

    if (clickedElement === "Leagues") {
      navigate("/leagues/");
    } else if (clickedElement === league_name) {
      navigate(
        `/leagues/league-listing/${league_id}?league_name=${league_name}&game_id=${gameId}`
      );
    }
    // else if (clickedElement === 'Teams') {
    //   navigate(`/leagues/teams-listing/${_ID}?game_id=${gameId}`);
    // }
  };

  function FormatMatches(response) {
    const TodayMatchesData = response.matches.today_matches.map(
      (matches, index) => {
        return {
          ...matches,
          teamA: matches.teamA_id.name,
          vs: "vs",
          teamB: matches.teamB_id.name,
          time: formattedTime(matches.time),
          date: new Date(matches.date).toLocaleDateString("en-GB"),
        };
      }
    );
    const UpcomingMatchesData = response.matches.upcoming_matches.map(
      (matches, index) => {
        return {
          ...matches,
          teamA: matches.teamA_id.name,
          vs: "vs",
          teamB: matches.teamB_id.name,
          time: formattedTime(matches.time),
          date: new Date(matches.date).toLocaleDateString("en-GB"),
        };
      }
    );
    const PreviousMatchesData = response.matches.previous_matches.map(
      (matches, index) => {
        return {
          ...matches,
          teamA: matches.teamA_id.name,
          vs: "vs",
          teamB: matches.teamB_id.name,
          time: formattedTime(matches.time),
          date: new Date(matches.date).toLocaleDateString("en-GB"),
        };
      }
    );

    setTodayMatches(TodayMatchesData);
    setUpcomingMatches(UpcomingMatchesData);

    setPreviousMatches(PreviousMatchesData);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (value) => {
    navigate(`/leagues/edit-match/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
  };

  const handleNavigate = () => {
    navigate(`/leagues/add-match/${_ID}`);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    // {
    //   label: "Teams",
    //   icon: "ri:team-line",
    //   handleClick: handleTeams,
    // },
    // {
    //   label: "Matches",
    //   icon: "fluent-emoji-high-contrast:vs-button",
    //   handleClick: handleMatches,
    // },
    // {
    //   label: "Assign to Admin",
    //   icon: "ri:admin-line",
    //   // handleClick: handleTeams,
    // },
  ];

  const formattedTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };

  const getAllMatch = async () => {
    setIsLoading(true);
    const response = await _get_match_listing_by_division_id(_ID);
console.log('response _get_match_listing_by_division_id',response);

    if (response.code === 200) {
      FormatMatches(response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_match(deleteValue);
    if (result.code === 200) {
      let new_today_matches_list = todayMatches.filter((matches) => {
        return matches._id !== deleteValue;
      });

      let new_upcoming_matches_list = upcomingMatches.filter((matches) => {
        return matches._id !== deleteValue;
      });
      let new_previous_matches_list = previousMatches.filter((matches) => {
        return matches._id !== deleteValue;
      });

      setTodayMatches(new_today_matches_list);
      setUpcomingMatches(new_upcoming_matches_list);
      setPreviousMatches(new_previous_matches_list);

      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllMatch();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Match?"}
        handleAgree={handleDelete}
      />

      <div className="row mb-4">
        <div className="row mb-4">
          <div className="col-lg-12 col-sm-12 mt-3">
            <div role="presentation" onClick={handleClickBreadcrumbs}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component="button">
                  Leagues
                </Link>
                <Link underline="hover" color="inherit" component="button">
                  {league_name}
                </Link>
                <Typography color="text.primary">{division_name}</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-sm-12 mt-3">
          <h3> Matches</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add New Match
          </button>
        </div>
      </div>

      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="Today" value="1" />
              <Tab label="Upcoming" value="2" />
              <Tab label="Previous" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={todayMatches}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </TabPanel>
          <TabPanel value="2">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={upcomingMatches}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </TabPanel>
          <TabPanel value="3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={previousMatches}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default MatchListing;
