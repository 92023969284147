import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_all_players= async (data) => {
    let requestObj = {
      path: `api/players/all_players?page=0&limit=0`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _detail_player= async (_id) => {
    let requestObj = {
      path: `api/players/detail_player/${_id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  
export const _add_player= async (data) => {
 
  const requestObj = {
    path: `api/players/add_player`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_player= async (id) => {
  const requestObj = {
    path: `api/players/delete_player/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
  };
  return invokeApi(requestObj);
};
export const _update_player = async (postData, _id) => {
  const requestObj = {
    path: `api/players/update_player/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _players_not_in_team = async (postData) => {
  const requestObj = {
    path: `api/players/players_not_in_team/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};




export const _players_listing_by_game_id= async (id) => {
  let requestObj = {
    path: `api/players/players_listing_by_game_id/${id}/?page=0&limit=0`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
     },
  };
  return invokeApi(requestObj);
};

