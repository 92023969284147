import React from "react";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import KeyIcon from "@mui/icons-material/Key";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

export default function ProfileMenu({
  anchorEl,
  handleClose,
  open,
  handleLogout,
  handleChangePassword,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <div className="profile-menu">
        {/* <p>Meta Logix</p>
        <p className="profile-menu-email">metalogix@gmail.com</p> */}
      </div>
      {/* <Divider sx={{ bgcolor: "black" }} /> */}
      {/* <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <PersonAdd sx={{ width: 24, height: 24 }} />
        </ListItemIcon>
        Update Profile
      </MenuItem> */}
      <MenuItem onClick={handleChangePassword}>
        <ListItemIcon>
          <KeyIcon sx={{ width: 24, height: 24 }} />
        </ListItemIcon>
        Change Password
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout sx={{ width: 24, height: 24 }} />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
}
