import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _get_all_games } from "../../DAL/game/game";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { Breadcrumbs } from "@mui/material";
import { Link } from "@material-ui/core";
import {
  _delete_league_by_super_admin,
  _get_all_league_listing,
  _get_division_listing,
  _update_division_status,
} from "../../DAL/leagues/leagues";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { Button, Typography, CircularProgress } from "@mui/material";

function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = date
    .toLocaleDateString("en-GB", options)
    .replace(/\//g, "-");
  return formattedDate;
}

const LeagueListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [allLeagues, setAllLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openStatucChange, setOpenStatucChange] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusType, setStatusType] = useState("");
  const [statusID, setStatusID] = useState("");

  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const urlParams = new URLSearchParams(window.location.search);
  let league_name = "League";
  let club_id = "club_id";
  let game_id = "game_id";

  if (urlParams.get("league_name") != null) {
    league_name = urlParams.get("league_name");
  }
  if (urlParams.get("game_id") != null && urlParams.get("game_id") !== "") {
    game_id = urlParams.get("game_id");
  } else {
    navigate("/leagues/");
  }
  if (urlParams.get("club_id") != null && urlParams.get("club_id") !== "") {
    club_id = urlParams.get("club_id");
  } else {
    navigate("/leagues/");
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "division_name", label: "DIVISION LEAGUES", alignRight: false },
    { id: "image", label: "IMAGE", alignRight: false, type: "thumbnail" },
    { id: "teams_count", label: "TEAMS", alignRight: false },
    { id: "start_date", label: "START DATE", alignRight: false },
    { id: "end_date", label: "END DATE", alignRight: false },
    { id: "game_title", label: "SPORT", alignRight: false },
    // { id: "status", label: "STATUS", alignRight: false, type: "row_status" },

    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              color={row.status === true ? "success" : "error"}
              onClick={() => handleTest(row.status, row._id)}
            >
              {row.status ? "Active" : "Inactive"}
            </Button>
          </>
        );
      },
    },

    { id: "action", label: "ACTION", alignRight: false, type: "action" },
  ];

  const [inputs, setInputs] = useState({
    division_name: "",
    game_id: "AllSports",
    search: "",
    status: true,
  });

  const handleClickBreadcrumbs = (event) => {
    event.preventDefault();

    const clickedElement = event.target.textContent;

    if (clickedElement === "Leagues") {
      navigate("/leagues/");
    }
    // else if (clickedElement === "Division Leagues") {
    //   navigate(`/leagues/league-listing/${_ID}`);
    // }
    // else if (clickedElement === 'Teams') {
    //   navigate(`/leagues/teams-listing/${_ID}?game_id=${gameId}`);
    // }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleEdit = (value) => {
    navigate(
      `/leagues/edit-league/${value._id}?club_id=${club_id}&league_id=${_ID}&game_id=${game_id}`,
      {
        state: value,
      }
    );
  };

  const handleTeams = (value) => {
    navigate(
      `/leagues/teams-listing/${value._id}?game_id=${value.game_id._id}&league_id=${_ID}&league_name=${league_name}&division_name=${value.division_name}`,
      {
        state: value,
      }
    );
  };
  const handleMatches = (value) => {
    navigate(
      `/leagues/matches-listing/${value._id}?league_id=${_ID}&game_id=${game_id}&league_name=${league_name}&division_name=${value.division_name}`,
      {
        state: value,
      }
    );
  };

  const handleTest = (status, status_id) => {
    setStatusType(status);
    setStatusID(status_id);
    setOpenStatucChange(true);
    handleCloseMenu();
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDrawer = () => {
    // setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
    navigate(`/leagues/add-league?club_id=${club_id}&league_id=${_ID}&game_id=${game_id}`);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    {
      label: "Teams",
      icon: "ri:team-line",
      handleClick: handleTeams,
    },
    // {
    //   label: "Matches",
    //   icon: "fluent-emoji-high-contrast:vs-button",
    //   handleClick: handleMatches,
    // },
  ];

  const updateLeagueStatus = async () => {
    setOpenStatucChange(false);
    setIsLoading(true);
    const response = await _update_division_status(
      {
        status: !statusType,
      },
      statusID
    );

    if (response.code === 200) {
      const updatedGameData = allLeagues.map((league) => {
        if (league._id === statusID) {
          return {
            ...league,
            status: !statusType,
          };
        }
        return league;
      });
      setAllLeagues(updatedGameData);
      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAllGames = async () => {
    const response = await _get_all_games();

    if (response.code === 200) {
      const GameData = response.games.map((game, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,
        };
      });

      setAllGames(GameData);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const getAllFilterLeagues = async () => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("filter_status", true);
    formData.append("search", inputs.search);
    formData.append("status", inputs.status);

    if (inputs.game_id === "AllSports") {
      formData.append("game_id", "");
    } else {
      formData.append("game_id", inputs.game_id);
    }

    const response = await _get_all_league_listing(formData);
    if (response.code === 200) {
      const GameData = response.leagues_data.map((game, index) => {
        let profileImage = {};
        const formattedStartDate = formatDate(game.start_date);
        const formattedEndDate = formatDate(game.end_date);

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          teams_count: game.teams.length,
          game_title: game.game_id.title,
        };
      });

      setAllLeagues(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAllLeagues = async () => {
    setIsLoading(true);
    const response = await _get_division_listing(
      {
        search: "",
      },
      _ID
    );
console.log('response _get_division_listing',response);
    if (response.code === 200) {
      const DivisionData = response.divisions_data.map((division, index) => {
        let profileImage = {};
        const formattedStartDate = formatDate(division.start_date);
        const formattedEndDate = formatDate(division.end_date);

        profileImage = {
          src: s3baseUrl + division.image,
          alt: division.image,
        };

        let title = "";
        if(division.game_id){
          title = division.game_id.title;
        }

        return {
          ...division,

          image: profileImage,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          teams_count: division.teams.length,
          game_title: title,
        };
      });

      setAllLeagues(DivisionData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_league_by_super_admin(deleteValue);
    if (result.code === 200) {
      let new_leagues_list = allLeagues.filter((league) => {
        return league._id !== deleteValue;
      });

      setAllLeagues(new_leagues_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleFilter = () => {
    getAllFilterLeagues();
  };
  const handleFilterReset = () => {
    setInputs((Inputs) => ({
      ...Inputs,
      game_id: "AllSports",
      search: "",
      status: true,
    }));
    getAllLeagues();
  };

  useEffect(() => {
    // setIsLoading(true);
    getAllLeagues();
    // getAllGames();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Division?"}
        handleAgree={handleDelete}
      />

      <CustomConfirmation
        open={openStatucChange}
        setOpen={setOpenStatucChange}
        title={"Are you sure you want to Change the Status of this Division?"}
        handleAgree={updateLeagueStatus}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Leagues"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        {/* <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div> */}

        {/* <div className="col-lg-8 col-sm-12 mt-3">
          <div className="row">
            <div className="col-md-4 col-xs-12 mb-2">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Sports</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="game_id"
                  value={inputs.game_id}
                  label="Sports"
                  onChange={handleChange}
                  defaultValue="AllSports" // Added defaultValue prop with an empty string
                >
                  <MenuItem key="AllSports" value="AllSports" selected>
                    All Sports
                  </MenuItem>

                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.title.charAt(0).toUpperCase() + game.title.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-4 col-xs-12 mb-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 mt-3 text-end">
          <div className=" ">
            <button className="normal-contained-button" onClick={handleFilter}>
              Filter
            </button>
            <button
              className="normal-contained-button"
              onClick={handleFilterReset}
            >
              Reset
            </button>
          </div>
        </div> */}

        <div className="row mb-4">
          <div className="col-lg-12 col-sm-12 mt-3">
            <div role="presentation" onClick={handleClickBreadcrumbs}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component="button">
                  Leagues
                </Link>
                <Typography color="text.primary">{league_name}</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-sm-12 mt-3">
          <h3> Division Leagues</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add Division League
          </button>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allLeagues}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default LeagueListing;
