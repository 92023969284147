import { Box, Card, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
// import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
// import { htmlDecode } from "src/utils/convertHtml";

export default function WeekCalendar({ events, handleClick, currentDate }) {
  const getByWeek = (events) => {
    // Define the start and end dates of the range
    const startDate = moment(currentDate.start_date, "YYYY-MM-DD");
    const endDate = moment(currentDate.end_date, "YYYY-MM-DD");

    // Filter events within the date range
    const filteredEvents = events.filter((event) => {
      return (
        moment(event.date).isSameOrAfter(startDate) &&
        moment(event.date).isSameOrBefore(endDate)
      );
    });

    let newArray = [];
    filteredEvents.forEach((event, i) => {
      let index = newArray.findIndex(
        (new_event) => new_event.date == event.date
      );
      if (index > -1) {
        newArray[index].events.push(event);
      } else {
        newArray.push({
          date: event.date,
          events: [event],
        });
      }
    });
    return newArray.sort(function (a, b) {
      var dateA = new Date(a.date);
      var dateB = new Date(b.date);
      return dateA - dateB;
    });
  };

       const formattedTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };


  return (
    <>
      <div >
        <div className="row">
          {getByWeek(events).length > 0 ? (
            getByWeek(events).map((weeks, index) => {
              return (
                <div className="col-12 col-lg-6">
                <>
                  <Card
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 1px 1px",
                      mb: 3,
                      backgroundColor: "#fcfcff", // Set the background color here
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      m={2}
                    >
                      <div className="text-center">
                        <div className="card-event-day">
                          <h6 className="calendar-day-heading">
                            {moment(weeks.date).format("ddd")}
                          </h6>
                          <span className="calendar-date-heading">
                            {moment(weeks.date).format("MM/DD")}
                          </span>
                        </div>
                      </div>
  
                      <div className="row">
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          // mb={2}
                          // mt={2}
                          ml={2}
                          mr={2}
                        >
                          
  
                          <div className=" ">

                          {weeks.events.map((event) => {
                              return (
                                <div
                                className="week-event-title cursor-pointer"
                                onClick={() => {
                                  handleClick(event);
                                }}
                              >
                                <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          // mb={2}
                          // mt={2}
                          // ml={2}
                          // mr={2}
                        > 


<div className=" ">
                            <Box
                              component="span"
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  bgcolor: "#2fed2f",
                                  width: 10,
                                  height: 10,
                                  borderRadius: "50%",
                                  marginRight: 1,
                                  border: "1px solid #00000099",
                                }}
                              />
                            </Box>
                          </div>

                          <div className="calendar-event-time-heading-outer">
                          <h6 className="calendar-event-heading">
                                {event.title}
                              </h6>
                              <p className="card-text calendar-time-heading">
                                <span>
                                  {`${formattedTime(event.time)} `}
                                  {/* {`${moment(event.start).format(
                                      "hh:mm A"
                                    )} - ${moment(event.end).format(
                                      "hh:mm A"
                                    )}`} */}
                                </span>
                              </p>
                          </div>
                              
                            </Stack>
                            </div>
 );
})}
                          </div>
                        </Stack>
                      </div>
                    </Stack>
                  </Card>
                </>
              </div>

                // <div className="col-12 col-md-6 calendar-week-card">
                //   <div className="card mb-3 calendar-setting">
                //     <div className="row g-0">
                //       <div className="col-md-4 d-flex align-items-center justify-content-center">
                //         {/* <img src="..." class="img-fluid rounded-start" alt="..." /> */}
                //         {/* <h5 className="card-title">Card title</h5> */}
                //         <div className="card-event-day">
                //           <h6>{moment(weeks.date).format("ddd")}</h6>
                //           <span>{moment(weeks.date).format("MM/DD")}</span>
                //         </div>
                //       </div>
                //       <div className="col-md-8">
                //         <div className="card-body">
                //           <div>
                //             {weeks.events.map((event) => {
                //               return (
                //                 <div className="row mb-2 mt-2">
                //                   <div className="col-md-1 d-flex align-items-center justify-content-center">
                //                     <Box
                //                       component="span"
                //                       sx={{
                //                         position: "relative",
                //                         display: "inline-flex",
                //                         alignItems: "center",
                //                       }}
                //                     >
                //                       <Box
                //                         component="span"
                //                         sx={{
                //                           bgcolor: "#2fed2f",
                //                           width: 10,
                //                           height: 10,
                //                           borderRadius: "50%",
                //                           marginRight: 1,
                //                           border: "3px solid #2fed2f",
                //                         }}
                //                       />
                //                     </Box>
                //                   </div>
                //                   <div className="col-md-11">
                //                     <h6 className="card-title1">
                //                       {event.title}
                //                     </h6>
                //                     <p className="card-text">
                //                       <span>
                //                       {`${formattedTime(event.time) } `}
                                     
                //                         {/* {`${moment(event.start).format(
                //                         "hh:mm A"
                //                       )} - ${moment(event.end).format(
                //                         "hh:mm A"
                //                       )}`} */}
                                      
                //                       </span>
                //                     </p>
                //                   </div>
                //                 </div>
                //               );
                //             })}
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
              );
            })
          ) : (
            // <RecordNotFound title="Events not found" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
              }}
            >
              <h2> Events not found </h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
