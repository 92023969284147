import { Box, Button, Card, Container, Stack, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
// import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
// import { htmlDecode } from "src/utils/convertHtml";

export default function DayCalendar({ events, handleClick, currentDate }) {
  const handleSortDate = (events) => {
    // Define the start and end dates of the range
    const startDate = moment(currentDate.start_date).format("YYYY-MM-DD");
    const endDate = moment(currentDate.end_date).format("YYYY-MM-DD");

    // Filter events within the date range
    const filteredEvents = events.filter((event) => {
      return (
        moment(event.date).isSameOrAfter(startDate) &&
        moment(event.date).isSameOrBefore(endDate)
      );
    });

    return filteredEvents;
  };

  const formattedTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };

  return (
    <div className="row">
      {handleSortDate(events).length > 0 ? (
        handleSortDate(events).map((event,index) => {
          return (
            <div className="col-12 col-lg-6" key={index}>
               
              <div
                  className="calendar-card cursor-pointer w-100"
                  onClick={() => {
                    handleClick(event);
                  }}
                >
                <Card
                
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 1px 1px",
                    mb: 3,
                    backgroundColor: "#fcfcff", // Set the background color here
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    m={2}
                  >
                    <div className="text-center">
                      <div className="card-event-day">
                        <h6 className="calendar-day-heading">
                          {moment(event.date).format("ddd")}
                        </h6>
                        <span className="calendar-date-heading">
                          {moment(event.date).format("MM/DD")}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        // mb={2}
                        // mt={2}
                        ml={2}
                        mr={2}
                      >
                        <div className=" ">
                          <Box
                            component="span"
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                bgcolor: "#2fed2f",
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                marginRight: 1,
                                border: "1px solid #00000099",
                              }}
                            />
                          </Box>
                        </div>

                        <div className=" ">
                          <div>
                            <h6 className="  calendar-event-heading">
                              {event.title}
                            </h6>
                            <p className="card-text calendar-time-heading">
                              <span>
                                {`${formattedTime(event.time)} `}
                                {/* {`${moment(event.start).format(
                                    "hh:mm A"
                                  )} - ${moment(event.end).format(
                                    "hh:mm A"
                                  )}`} */}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </Stack>
                </Card>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
          }}
        >
          <h2> Events not found </h2>
        </div>
      )}
    </div>
  );
}
