import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
//SidebarConfig-icons
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  // {
  //   title: "Admin Users",
  //   icon: getIcon("ant-design:team-outlined"),
  //   child_options: [
  //     {
  //       title: "Approved",
  //       path: "/admins/approved-users",
  //       icon: getIcon("mdi:user-check"),
  //     },
  //     {
  //       title: "Not Approved",
  //       path: "/admins/not-approved-users",
  //       icon: getIcon("mdi:user-alert"),
  //     },
  //   ],
  // },
  {
    title: "Clubs",
    icon: getIcon("ant-design:team-outlined"),
    child_options: [
      {
        title: "Approved Clubs",
        path: "/clubs/approved-clubs",
        icon: getIcon("mdi:user-check"),
      },
      {
        title: "Not Approved Clubs",
        path: "/clubs/not-approved-clubs",
        icon: getIcon("mdi:user-alert"),
      },
    ],
  },
  // {
  //   title: "Managers",
  //   path: "/managers",
  //   icon: getIcon("ph:users-three-fill"),
  // },

  {
    title: "Match",
    icon: getIcon("fluent-emoji-high-contrast:vs-button"),
    child_options: [
      {
        title: "Matches",
        path: "/matches",
        icon: getIcon("fluent-emoji-high-contrast:vs-button"),
      },
      {
        title: "Matches History",
        path: "/matches-history",
        icon: getIcon("material-symbols:history"),
      },
      {
        title: "Calendar",
        path: "/calendar",
        icon: getIcon("ph:calendar"),
      },
    ],
  },
 
  {
    title: "Sports",
    path: "/sports",
    icon: getIcon("fluent:sport-american-football-24-filled"),
  },

  {
    title: "Leagues",
    path: "/leagues",
    icon: getIcon("fluent:sport-soccer-24-filled"),
  },
  {
    title: "Teams",
    path: "/teams",
    icon: getIcon("ri:team-fill"),
  },
  {
    title: "Players",
    path: "/players",
    icon: getIcon("ant-design:team-outlined"),
  },
];

export default SidebarConfig;
