import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";

import { _players_not_in_team } from "../../DAL/players/players";
import {
  _add_teams_of_division,
  _teams_of_league_players_by_team_id,
} from "../../DAL/leagues/leagues";

function AddPlayers() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allSelectedPlayers, setAllSelectedPlayers] = useState([]);

  const [inputs, setInputs] = useState({
    league_name: "",
    teams: [],
    players: [],
    game_id: "",
    start_date: "",
    end_date: "",
    status: true,
    dob: "",
  });
  const urlParams = new URLSearchParams(window.location.search);
  const param = useParams();
  const division_id = param.id;
  const team_id = urlParams.get("teamId");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (division_id !== null && team_id !== null && team_id !== "") {
      const formData = new FormData();
      formData.append("players", JSON.stringify(inputs.players));
      formData.append("division_id", division_id);
      formData.append("team_id", team_id);
      setIsLoading(true);
      const result = await _add_teams_of_division(formData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      navigate("/leagues/");
    }
  };

  const getSelectedPlayers = async () => {
    if (division_id !== null && team_id !== null && team_id !== "") {
      setIsLoading(true);
      const response = await _teams_of_league_players_by_team_id(
        {
          division_id: division_id,
        },
        team_id
      );
      if (response.code === 200) {
        setInputs({
          ...inputs,
          players: response.team_players.players.map((player) => player._id),
        });
        setIsLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      navigate("/leagues/");
    }
  };

  const getPlayersNotInTeam = async () => {
    if (division_id !== null && team_id !== null && team_id !== "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("division", division_id);
      formData.append("team", team_id);
      const response = await _players_not_in_team(formData);
      if (response.code === 200) {
        setAllSelectedPlayers(response.players);
        setIsLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      navigate("/leagues/");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getPlayersNotInTeam();
    getSelectedPlayers();
    // if (division_id === null) {
    //   navigate("/leagues/");
    // } else if (team_id === null) {
    //   navigate("/leagues/");
    // }
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Players</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-12 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Name *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="players"
                  value={inputs.players}
                  required
                  label="players"
                  multiple // Enable multi-selection
                  onChange={handleChange}
                >
                  {allSelectedPlayers.map((player) => (
                    <MenuItem key={player._id} value={player._id}>
                      {`${player.first_name} ${player.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddPlayers;
