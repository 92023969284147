import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { _all_app_admins } from "../../DAL/admin/admin";

import {
  _assign_league_to_admins,
  _main_league_detail,
} from "../../DAL/leagues/leagues";
import { _club_managers, _get_list_club } from "../../DAL/clubs/clubs";
import { s3baseUrl } from "../../config/config";

function LeagueAssignAdmin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allAdmins, setAllAdmins] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;
  const [inputs, setInputs] = useState({
    league_name: "",
    admin: [],
    club_id: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleClubChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    getAllAdmins(value);
  };

  const handle_format_data = (data) => {
    const usersArray = data.users.map(({ _id }) => _id);
    setInputs({
      ...inputs,
      league_name: data.league_name,
      admin: usersArray,
    });
  };

  const get_detail_league = async () => {
    setIsLoading(true);
    const response = await _main_league_detail(_ID);
    if (response.code === 200) {
      handle_format_data(response.league);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const result = await _assign_league_to_admins(
      {
        admin_ids: JSON.stringify(inputs.admin),
      },
      _ID
    );

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAllClubs = async () => {
    const response = await _get_list_club({
      // "text":"zzz",
      approval_status: true,
    });

    console.log("response _get_list_club", response);

    if (response.code === 200) {
      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };

        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string

        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
        };
      });

      setAllClubs(ClubData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };


  const getAllAdmins = async (CLUB_ID) => {
    const response = await _club_managers(CLUB_ID);
    console.log('response _club_managers',response);

    if (response.code === 200) {
      const AdminData = response.managers.map((Admin, index) => {
        let profileImage = {};
        // let dateStr = Admin.createdAt;
        // let date = new Date(dateStr);
        // let day = date.getDate();
        // let month = date.getMonth() + 1; // Adding 1 to get the correct month (January is 0-based)
        // let year = date.getFullYear();
        // let formattedDate = `${day < 10 ? "0" + day : day}-${
        //   month < 10 ? "0" + month : month
        // }-${year}`;

        if (Admin.profile_image === "picture") {
          profileImage = {
            src: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
            alt: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
          };
        } else {
          profileImage = {
            src: s3baseUrl + Admin.profile_image,
            alt: Admin.profile_image,
          };
        }

        return {
          ...Admin,
          profile_image: profileImage,
        //   createdAt: formattedDate,
        };
      });

      setAllAdmins(AdminData);
      setIsLoading(false);
      console.log('ALL Managers  _club_managers',AdminData);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!state) {
      get_detail_league();
    } else {
      handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
    // get_detail_league();
    getAllClubs();
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">ASSIGN CLUB</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="League Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="league_name"
                value={inputs?.league_name}
                // value={league_name}
                onChange={handleChange}
                disabled
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Clubs*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="club_id"
                  value={inputs.club_id}
                  required
                  label="Clubs"
                  onChange={handleClubChange}
                >
                  {allClubs.map((club) => (
                    <MenuItem key={club._id} value={club._id}>
                      {club.club_name.charAt(0).toUpperCase() +
                        club.club_name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>


            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Managers</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="manager_id"
                  value={inputs.manager_id}
                  
                  label="Managers"
                  onChange={handleChange}
                >
                  {allAdmins.map((manager) => (
                    <MenuItem key={manager._id} value={manager._id}>
                      {manager.first_name.charAt(0).toUpperCase() +
                        manager.first_name.slice(1) + " " + manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default LeagueAssignAdmin;
