import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  IconButton,
  Card,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Badge,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { avatar_base_url, s3baseUrl } from "../../config/config";
import {
  _main_league_detail,
  _update_main_league,
} from "../../DAL/leagues/leagues";
import { _club_active_managers, _club_managers, _get_list_club } from "../../DAL/clubs/clubs";

const Input = styled("input")({
  display: "none",
});
function EditMainLeague() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;
  
  const [allClubs, setAllClubs] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);

  const [inputs, setInputs] = useState({
    league_name: "",
    status: true,
    club_id: "",
    manager_id: [],
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClubChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ 
      ...values, [name]: value,
      manager_id: [],
    }));
    // getAllAdmins(value);
  };

  const handle_format_data = (data) => {
    console.log('handle_format_data',data);

    let test = {
      ...data,
      club_id :data.club._id,
 
      // manager_id:data.club.managers.map((manager) => manager.user_id),
      // managers:data.club.managers,
    }
    setInputs(test);
    // setInputs({
    //   ...data,
    //   club_id :data.club._id,
    //   // manager_id: data.club.managers,
    //   manager_id:data.club.managers.map((manager) => manager._id),
    // });
    console.log('test',test);
    setPreviews(data.image.src);
    // const managers_id = data.managers.map((manager) => manager._id);

    // getAllAdmins(data.club._id);
  };
  const get_detail_league = async () => {
    setIsLoading(true);
    const response = await _main_league_detail(_ID);
    console.log('response _main_league_detail',response);
    if (response.code === 200) {
      let LeagueData = response.league;
 
      let newLeagueData = {
        ...LeagueData,
        image: {
          src: s3baseUrl + LeagueData.image,
          alt: LeagueData.image,
        },
        // manager_id:LeagueData.club.managers,
      };

      handle_format_data(newLeagueData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("league_name", inputs.league_name);
    formData.append("status", inputs.status);
    formData.append("club_id", inputs.club_id);
    // formData.append("club_id", inputs.club_id);
  // formData.append("manager_id", inputs.manager_id);
  // formData.append("managers", JSON.stringify(inputs.manager_id));

    if (inputs.league_name.trim() === "") {
      error = "Please! Enter League Name";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      if (image && error === "") {
        if (image.type.startsWith("image/")) {
          formData.append("image", image);
          setIsLoading(true);
          const result = await _update_main_league(formData, _ID);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(true);
        const result = await _update_main_league(formData, _ID);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };


  
  const getAllClubs = async () => {
    const response = await _get_list_club({
      // "text":"zzz",
      approval_status: true,
    });

    console.log("response _get_list_club", response);

    if (response.code === 200) {
      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };

        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string

        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
        };
      });

      setAllClubs(ClubData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };


  
  const getAllAdmins = async (CLUB_ID) => {
    setIsLoading(true);
    const response = await _club_active_managers(CLUB_ID);
    console.log('response _club_active_managers',response);

    if (response.code === 200) {
      const AdminData = response.managers.map((Admin, index) => {
        let profileImage = {};
        // let dateStr = Admin.createdAt;
        // let date = new Date(dateStr);
        // let day = date.getDate();
        // let month = date.getMonth() + 1; // Adding 1 to get the correct month (January is 0-based)
        // let year = date.getFullYear();
        // let formattedDate = `${day < 10 ? "0" + day : day}-${
        //   month < 10 ? "0" + month : month
        // }-${year}`;

        if (Admin.profile_image === "picture") {
          profileImage = {
            src: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
            alt: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
          };
        } else {
          profileImage = {
            src: s3baseUrl + Admin.profile_image,
            alt: Admin.profile_image,
          };
        }

        return {
          ...Admin,
          profile_image: profileImage,
        //   createdAt: formattedDate,
        };
      });

      setAllAdmins(AdminData);
      setIsLoading(false);
      console.log('ALL Managers  _club_active_managers',AdminData);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };


   

  useEffect(() => {
  console.log('inputs',inputs);
  }, [inputs]);

  useEffect(() => {
    getAllClubs();
    if (!state) {
      get_detail_league();
    } else {
      handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit League</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="League Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="league_name"
                value={inputs?.league_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-12 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Who Host This League*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="club_id"
                  value={inputs.club_id}
                  required
                  label="Who Host This League"
                  onChange={handleClubChange}
                >
                  {allClubs.map((club) => (
                    <MenuItem key={club._id} value={club._id}>
                      {club.club_name.charAt(0).toUpperCase() +
                        club.club_name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Managers / Optional</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="manager_id"
                  value={inputs.manager_id}
                  
                  label="Managers / Optional"
                  onChange={handleChange}
                  multiple // Enable multi-selection
                >
                  {allAdmins.map((manager) => (
                    <MenuItem key={manager.user_id} value={manager.user_id}>
                      {manager.first_name.charAt(0).toUpperCase() +
                        manager.first_name.slice(1) + " " + manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            
            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditMainLeague;
