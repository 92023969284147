import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_all_league_listing= async () => {
  let requestObj = {
    path: `api/divisions/division_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_all_active_division= async () => {
  let requestObj = {
    path: `api/divisions/get_all_active_division`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_division_listing = async (data,_id) => {
    let requestObj = {
      path: `api/divisions/get_division_listing/${_id}?page=0&limit=0`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
 
// export const _get_all_league_listing = async (data) => {
//     let requestObj = {
//       path: `api/leagues/get_league_listing?page=0&limit=0`,
//       method: "PUT",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };

  

  export const _teams_listing_by_division_id= async (id) => {
    let requestObj = {
      path: `api/divisions/teams_listing_by_division_id/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
 

  // export const _teams_listing_by_league_id= async (id) => {
  //   let requestObj = {
  //     path: `api/leagues/teams_listing_by_league_id/${id}`,
  //     method: "GET",
  //     headers: {
  //       "x-sh-auth": localStorage.getItem("token"),
  //     },
  //   };
  //   return invokeApi(requestObj);
  // };

  
  export const _add_division= async (data) => {
 
    const requestObj = {
      path: `api/divisions/add_division`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
         "Content-Type": "multipart/form-data",
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  
// export const _add_league= async (data) => {
 
//   const requestObj = {
//     path: `api/leagues/add_league`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//        "Content-Type": "multipart/form-data",
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

export const _delete_league_by_super_admin= async (id) => {
  const requestObj = {
    path: `api/divisions/delete_division_by_super_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
  };
  return invokeApi(requestObj);
};
export const _update_division_by_super_admin = async (postData, _id) => {
  const requestObj = {
    path: `api/divisions/update_division_by_super_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _update_league_by_super_admin = async (postData, _id) => {
  const requestObj = {
    path: `api/leagues/update_league_by_super_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _teams_of_league_players_by_team_id = async (postData, _id) => {
  const requestObj = {
    path: `api/teams_of_division/players_by_team_id/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
     
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};


export const _add_teams_of_division= async (data) => {
 
  const requestObj = {
    path: `api/teams_of_division/add_teams_of_division`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// export const _add_teams_of_league= async (data) => {
 
//   const requestObj = {
//     path: `api/teams_of_league/add_teams_of_league`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//        "Content-Type": "multipart/form-data",
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };


export const _get_match_listing_by_division_id= async (id) => {
  let requestObj = {
    path: `api/matches/get_match_listing_by_division_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
     },
  };
  return invokeApi(requestObj);
}; 
// export const _get_match_listing_by_league_id= async (id) => {
//   let requestObj = {
//     path: `api/matches/get_match_listing_by_league_id/${id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//      },
//   };
//   return invokeApi(requestObj);
// };


export const _add_match= async (data) => {
 
  const requestObj = {
    path: `api/matches/add_match`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const _update_match = async (postData, _id) => {
  const requestObj = {
    path: `api/matches/update_match/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};


export const _delete_match= async (id) => {
  const requestObj = {
    path: `api/matches/delete_match/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
  };
  return invokeApi(requestObj);
};


export const _detail_division_by_super_admin= async (_id) => {
  let requestObj = {
    path: `api/divisions/detail_division_by_super_admin/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// export const _detail_league_by_super_admin= async (_id) => {
//   let requestObj = {
//     path: `api/leagues/detail_league_by_super_admin/${_id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };


export const _assign_division_to_admins = async (postData, _id) => {
  const requestObj = {
    // path: `api/division/assign_division_to_admins/${_id}`,
    path: `api/divisions/assign_division_to_admins/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _assign_league_to_admins = async (postData, _id) => {
  const requestObj = {
    // path: `api/division/assign_division_to_admins/${_id}`,
    path: `api/leagues/assign_league_to_admins/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
// export const _assign_league_to_admins = async (postData, _id) => {
//   const requestObj = {
//     path: `api/leagues/assign_league_to_admins/${_id}`,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
       
//     },
//     postData: postData,
//   };
//   return invokeApi(requestObj);
// };

export const _detail_match= async (_id) => {
  let requestObj = {
    path: `api/matches/detail_match/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_game_score_card= async (_id) => {
  let requestObj = {
    path: `api/matches/get_game_score_card/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_division_status = async (postData, _id) => {
  const requestObj = {
    path: `api/divisions/update_division_status/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _update_league_status = async (postData, _id) => {
  const requestObj = {
    path: `api/leagues/update_league_status/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

// Main league
export const _get_all_main_leagues= async (_id) => {
  let requestObj = {
    path: `api/leagues/all_leagues?page=0&limit=0`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_main_league= async (data) => {
 
  const requestObj = {
    path: `api/leagues/add_league`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_main_league = async (postData, _id) => {
  const requestObj = {
    path: `api/leagues/update_league/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

  export const _delete_Main_league= async (id) => {
    const requestObj = {
      path: `api/leagues/delete_league/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
         
      },
    };
    return invokeApi(requestObj);
  };

  export const _main_league_detail= async (id) => {
    let requestObj = {
      path: `api/leagues/league_detail/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };