import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
// import CustomMUITable from "../../components/GeneralComponents/CustomMUITable";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { _teams_of_league_players_by_team_id } from "../../DAL/leagues/leagues";
import { _delete_player, _get_all_players } from "../../DAL/players/players";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { Link } from "@material-ui/core";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "first_name", label: "first_name", alignRight: false },
  { id: "last_name", label: "last_name", alignRight: false },
  { id: "image", label: "IMAGE", alignRight: false, type: "thumbnail" },
];

const LeaguePlayersListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const param = useParams();
  let { state } = useLocation();
  const ID = param.id;
  const urlParams = new URLSearchParams(window.location.search);

  let league_name = "League";
  let division_name = "Division";
  let team_name = "Team";
  let gameId = "";
  let teamId = "";
  let league_id = "";

  if (urlParams.get("game_Id") != null && urlParams.get("game_Id") !== "") {
    gameId = urlParams.get("game_Id");
  } else {
    navigate("/leagues/");
  }

  if (urlParams.get("teamId") != null && urlParams.get("teamId") !== "") {
    teamId = urlParams.get("teamId");
  } else {
    navigate("/leagues/");
  }

  if (urlParams.get("league_id") != null && urlParams.get("league_id") !== "") {
    league_id = urlParams.get("league_id");
  } else {
    navigate("/leagues/");
  }

  if (urlParams.get("league_name") != null) {
    league_name = urlParams.get("league_name");
  }

  if (urlParams.get("division_name") != null) {
    division_name = urlParams.get("division_name");
  }

  if (urlParams.get("team_name") != null) {
    team_name = urlParams.get("team_name");
  }

  const division_id = ID;
  const _ID = teamId;

  const handleEdit = (value) => {
    navigate(`/players/edit-player/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
    navigate(
      `/leagues/add-players/${division_id}?gameId=${gameId}&teamId=${_ID}`
    );
  };

  const handleClickBreadcrumbs = (event) => {
    event.preventDefault();

    const clickedElement = event.target.textContent;

    if (clickedElement === "Leagues") {
      navigate("/leagues/");
    } else if (clickedElement === league_name) {
      navigate(
        `/leagues/league-listing/${league_id}?league_name=${league_name}&game_id=${gameId}`
      );
    } else if (clickedElement === division_name) {
      navigate(
        `/leagues/teams-listing/${division_id}?game_id=${gameId}&league_id=${league_id}&league_name=${league_name}&division_name=${division_name}`
      );
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const getAllgames = async () => {
    const response = await _teams_of_league_players_by_team_id(
      {
        division_id: division_id,
      },
      _ID
    );

    if (response.code === 200) {
      const GameData = response.team_players.players.map((player, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + player.image,
          alt: player.image,
        };

        return {
          ...player,
          image: profileImage,
        };
      });

      setAllGames(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_player(deleteValue);
    if (result.code === 200) {
      let new_players_list = allGames.filter((player) => {
        return player._id !== deleteValue;
      });

      setAllGames(new_players_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // setIsLoading(true);
    getAllgames();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Player?"}
        handleAgree={handleDelete}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Players"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-12 col-sm-12 mt-3">
          <div role="presentation" onClick={handleClickBreadcrumbs}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component="button">
                Leagues
              </Link>

              <Link underline="hover" color="inherit" component="button">
                {league_name}
              </Link>

              <Link underline="hover" color="inherit" component="button">
                {division_name}
              </Link>
              <Typography color="text.primary">{team_name}</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Players</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            + Add Players
          </button>
        </div>
      </div>

      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allGames}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default LeaguePlayersListing;
