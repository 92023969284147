import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _add_admin } from "../../DAL/admin/admin";
import { _add_game } from "../../DAL/game/game";
import { avatar_base_url } from "../../config/config";

const Input = styled("input")({
  display: "none",
});
function AddSport() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    squad_count: "",
    lineup_count: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);
    formData.append("squad_count", inputs.squad_count);
    formData.append("lineup_count", inputs.lineup_count);

    //     Squad Count = Total member count
    // Line up Count = Playing member count

    if (inputs.squad_count > 20) {
      error = "Total member count must be less than or equal to 20";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.lineup_count > 20) {
      error = "Playing member count must be less than or equal to 20";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.squad_count < 1) {
      error = "Total member count must be greater than or equal to 1";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.lineup_count < 1) {
      error = "Playing member count must be greater than or equal to 1";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.lineup_count > inputs.squad_count) {
      error =
        "Playing member count must be less than or equal to Total member count";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.title.trim() === "") {
      error = "Please enter the Sport Name";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";
      if (image && error == "") {
        if (image.type.startsWith("image/")) {
          formData.append("image", image);

          setIsLoading(true);
          const result = await _add_game(formData);

          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Sport</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Sport Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="title"
                value={inputs?.title}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Total member count"
                type="number"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="squad_count"
                value={inputs?.squad_count}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Playing member count"
                type="number"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="lineup_count"
                value={inputs?.lineup_count}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddSport;
