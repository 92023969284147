import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  TextField,
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tab,
  Box,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers";
import {
  _detail_match,
  _get_game_score_card,
  _update_match,
} from "../../DAL/leagues/leagues";
import {
  Calendar,
  Match,
  Onefootball,
  Penalty,
  ProfilePic,
  RedCard,
  Stadium,
  Substitute,
  Team_A,
  Team_B,
  Threefootball,
  Whistle,
  YellowCard,
} from "../../assets";
import { s3baseUrl } from "../../config/config";

function MatchesHistoryDetails() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [value, setValue] = React.useState("2");

  const [teamOnePlayers, setTeamOnePlayers] = useState([]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([]);
  const [tableInfoData, setTableInfoData] = useState([]);
  const [tableData, setTableData] = useState([]);
  //   {
  //     mins: "3'",
  //     player1: "Karim Benzema",
  //     image_1: 3,
  //     image_2: 0,
  //     score1: "1 - 0",
  //     score2: "0 - 0",
  //     player2: "",
  //   },
  //   {
  //     mins: "24'",
  //     player1: "Marco Asensio",
  //     image_1: 3,
  //     image_2: 0,
  //     score1: "2 - 0",
  //     score2: "0 - 0",
  //     player2: "",
  //   },
  //   {
  //     mins: "25'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 3,
  //     score1: "2 - 0",
  //     score2: "0 - 1",
  //     player2: "Kang-in Lee",
  //   },
  //   {
  //     mins: "29'",
  //     player1: "Marco Asensio",
  //     image_1: 1,
  //     image_2: 0,
  //     score1: "2 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   { mins: "HT'", player1: "", score1: "2 - 1", score2: "0 - 1", player2: "" },

  //   {
  //     mins: "47'",
  //     player1: "Eduardo Camavinga",
  //     image_1: 4,
  //     image_2: 0,
  //     score1: "2 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   {
  //     mins: "50'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 2,
  //     score1: "2 - 1",
  //     score2: "0 - 1",
  //     player2: "David Soria",
  //   },
  //   {
  //     mins: "55'",
  //     player1: "Marco Asensio",
  //     image_1: 0,
  //     image_2: 0,
  //     score1: "3 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   {
  //     mins: "57'",
  //     player1: "Idrissa Baba",
  //     image_1: 5,
  //     image_2: 0,
  //     score1: "3 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   {
  //     mins: "60'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 11,
  //     score1: "3 - 1",
  //     score2: "0 - 1",
  //     player2: "idrissa Baba",
  //   },
  //   {
  //     mins: "62'",
  //     player1: "William Jose",
  //     image_1: 2,
  //     image_2: 0,
  //     score1: "3 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   {
  //     mins: "67'",
  //     player1: "Nacho Fernandez",
  //     image_1: 4,
  //     image_2: 0,
  //     score1: "3 - 1",
  //     score2: "0 - 1",
  //     player2: "",
  //   },
  //   {
  //     mins: "78'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 1,
  //     score1: "",
  //     score2: "",
  //     player2: "Karim Benzem",
  //   },
  //   {
  //     mins: "84'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 3,
  //     score1: "3 - 1",
  //     score2: "1 - 2",
  //     player2: "Isco",
  //   },
  //   {
  //     mins: "FT'",
  //     player1: "",
  //     image_1: 0,
  //     image_2: 0,
  //     score1: "3 - 1",
  //     score2: "1 - 2",
  //     player2: "",
  //   },
  // ]);

  const [matchData, setMatchData] = useState({
    teamA_name: "",
    teamA_image: "",
    teamA_total_point: "",
    teamA_total_score: "",
    teamA_players: [],
    teamA_reserved_players: [],

    teamB_name: "",
    teamB_image: "",
    teamB_total_point: "",
    teamB_total_score: "",
    teamB_players: [],
    teamB_reserved_players: [],
  });

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setInputs((values) => ({
      ...values,
      date: dayjs(date),
      format_date: formattedDate,
    }));
  };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const formattedAnyTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };

  const handle_format_data = (data) => {
    console.log("data handle_format_data", data);

    const data_date = data.date;
    const [day, month, year] = data_date.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    const data_time = data.time;
    const [time, meridiem] = data_time.split(" ");
    const [hours, minutes] = time.split(":");
    let formattedHours = parseInt(hours);
    if (meridiem === "PM") {
      formattedHours += 12;
    }
    const formattedTime = `${formattedHours}:${minutes}`;

    setInputs({
      ...inputs,

      division_id: data.division_id._id,

      division_name: data.division_id.division_name,
      game: data.game_id.data,

      limit: data.limit,
      match_type: data.match_type,

      teamA_name: data.teamA_id.name,
      teamB_name: data.teamA_id.name,
      toss: data.toss,
      tv_umpire: data.tv_umpire,

      location: data.location,
      status: data.status,
      data_time: data.time, //"12:10 PM"
      data_date: data.date, //"25/05/2023"

      date1: dayjs(data.date, "DD/MM/YYYY").toDate(),
      date: dayjs(new Date(dayjs(data.date, "DD/MM/YYYY").toDate())),

      time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),

      format_time: formattedAnyTime(formattedTime),
      format_date: formattedDate,
    });
  };

  const get_detail_match = async () => {
    setIsLoading(true);
    const response = await _detail_match(_ID);
    console.log("response _detail_match", response);

    if (response.code === 200) {
      let MatchData = response.match;

      const dateString = MatchData.date;
      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString("en-GB"); // Adjust the locale based on your desired date format

      const timeString = MatchData.time;
      const [hours, minutes] = timeString.split(":");
      const timeObj = new Date();
      timeObj.setHours(hours);
      timeObj.setMinutes(minutes);
      const formattedTime = timeObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      let newMatchData = {
        ...MatchData,

        date: formattedDate,
        time: formattedTime,
      };

      handle_format_data(newMatchData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const testValidDateChange = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth) ||
      (selectedYear === currentYear &&
        selectedMonth === currentMonth &&
        selectedDay >= currentDay)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let error = "";
    // const formData = new FormData();

    // formData.append("date", inputs.format_date);
    // formData.append("time", inputs.format_time);
    // formData.append("location", inputs.location);
    // formData.append("status", inputs.status);

    // if (inputs.format_date === "") {
    //   error = "Please! Select a date";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (inputs.format_date === "Invalid Date") {
    //   error = "Invalid Date!";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (testValidDateChange(inputs.format_date) === false) {
    //   error = "Please select today's date or a date in the future!";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (inputs.format_time === "") {
    //   error = "Please! Select a Time";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (inputs.format_time === "Invalid Date") {
    //   error = "Invalid Time!";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (inputs.location.trim() === "") {
    //   error = "Please! Select a Venue";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else {
    //   error = "";
    //   if (error === "") {
    //     setIsLoading(true);
    //     const result = await _update_match(formData, _ID);

    //     if (result.code === 200) {
    //       enqueueSnackbar(result.message, { variant: "success" });
    //       navigate(-1);
    //       setIsLoading(false);
    //     } else {
    //       enqueueSnackbar(result.message, { variant: "error" });
    //       setIsLoading(false);
    //     }
    //   }
    // }
  };
  function formatDate(dateStr) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateStr);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}, ${dayOfWeek}`;
    return formattedDate;
  }

  const getGameScoreCard = async () => {
    setIsLoading(true);
    // 64d050d83c1e4c0f2cbbd47e
    // 64d04da93c1e4c0f2cbbd3c0
    // const response = await _get_game_score_card("64d04da93c1e4c0f2cbbd3c0");
    const response = await _get_game_score_card(_ID);
    console.log("response _get_game_score_card", response);

    let data = response.game_score_card;

    if (response.code === 200) {
      setMatchData({
        teamA_name: data.teamA_id.name,
        teamA_image: s3baseUrl + data.teamA_id.image,
        teamA_total_point: data.teamA_id.total_point,
        teamA_total_score: data.teamA_id.total_score,
        teamA_players: data.teamA_id.players,
        teamA_reserved_players: data.teamA_id.reserved_players,

        teamB_name: data.teamB_id.name,
        teamB_image: s3baseUrl + data.teamB_id.image,
        teamB_total_point: data.teamB_id.total_point,
        teamB_total_score: data.teamB_id.total_score,
        teamB_players: data.teamB_id.players,
        teamB_reserved_players: data.teamB_id.reserved_players,

        date: formatDate(data.match_id.date),
        location: data.match_id.date,
        refree: "",
      });

      setTableInfoData([
        {
          title: "Date",
          text: formatDate(data.match_id.date),
          image_1: 12,
        },
        {
          title: "Venue",
          text: data.match_id.location,
          image_1: 14,
        },
        {
          title: "Refree",
          text: "",
          image_1: 13,
        },
      ]);

      console.log("data", data);
      console.log("data data.score_card", data.score_card);

      const formatTableData = data.score_card.map((score, index) => {
        let scoreMins = score.mints;
        let player1Name = "";
        let subsitutePlayer = "";
        let subsitutePlayer2 = "";

        if (score.player_id) {
          if (score.player_id.team_name === data.teamA_id.name) {
            player1Name = `${score.player_id.first_name} ${score.player_id.last_name}`;
          }
        }

        let player2Name = "";
        if (score.player_id) {
          if (score.player_id.team_name === data.teamB_id.name) {
            player2Name = `${score.player_id.first_name} ${score.player_id.last_name}`;
          }
        }

        let Score1 = "0 - 0";
        let Score2 = "0 - 0";

        Score1 = `${score.teamA_score} - ${score.teamA_point}`;
        Score2 = `${score.teamB_score} - ${score.teamB_point}`;

        let player1Action = 0;
        let player2Action = 0;

        // Team A
        if (score.action._id !== 5 && score.action._id !== 6) {
          if (score.player_id) {
            if (score.player_id.team_name === data.teamA_id.name) {
              player1Action = score.action._id + 1;
              if (score.action._id === 4) {
                subsitutePlayer = `${score.subsitute_player_id.first_name} ${score.subsitute_player_id.last_name}`;
              }
            }
          }
          if (score.action._id === 3) {
            if (score.team_id.name === data.teamA_id.name) {
              player1Action = score.action._id + 1;
            }
          }
        }

        // Team B
        if (score.action._id !== 5 && score.action._id !== 6) {
          if (score.player_id) {
            if (score.player_id.team_name === data.teamB_id.name) {
              player2Action = score.action._id + 1;
              if (score.action._id === 4) {
                subsitutePlayer2 = `${score.subsitute_player_id.first_name} ${score.subsitute_player_id.last_name}`;
              }
            }
          }

          if (score.action._id === 3) {
            if (score.team_id.name === data.teamB_id.name) {
              player2Action = score.action._id + 1;
            }
          }
        }

        if (score.action._id === 5) {
          scoreMins = "HT";
        }
        if (score.action._id !== 5 && score.action._id === 6) {
          scoreMins = "FT";
        }

        return {
          ...score,

          // data.teamA_id.name

          mins: `${scoreMins}'`,
          player1: player1Name,
          subsitute_player: subsitutePlayer,

          image_1: player1Action,
          image_2: player2Action,
          score1: Score1,
          score2: Score2,
          player2: player2Name,
          subsitute_player2: subsitutePlayer2,
        };
      });
      setTableData(formatTableData);
      // const [teamOnePlayers, setTeamOnePlayers] = useState([]);

      // const player1Data = [
      //   {
      //     name: "Claudio Bravo",
      //     image_1: 6,
      //   },

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (!state) {
    //   get_detail_match();
    // } else {
    //   handle_format_data(state);
    //   window.history.replaceState({}, document.title);
    // }
    getGameScoreCard();
    // api/matches/get_game_score_card/64cc99ce2d436d04e8c05511
  }, []);

  function getImagesByUsingNumbers(number) {
    // console.log("number", number);

    if (number === 0) {
      return `Empty`;
    } else if (number === 1) {
      return Threefootball;
    } else if (number === 2) {
      return YellowCard;
    } else if (number === 3) {
      return RedCard;
    } else if (number === 4) {
      return Penalty;
    } else if (number === 5) {
      return Substitute;
    } else if (number === 6) {
      return `Empty`;
    } else if (number === 7) {
      return `Empty`;
    } else if (number === 8) {
      return Onefootball;
    } else if (number === 9) {
      return Match;
    } else if (number === 10) {
      return Team_A;
    } else if (number === 11) {
      return Team_B;
    } else if (number === 12) {
      return Calendar;
    } else if (number === 13) {
      return Whistle;
    } else if (number === 14) {
      return Stadium;
    } else if (number === 15) {
      return ProfilePic;
    } else {
      return `Empty`;
    }
  }

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12 text-center">
          <h3 className="my-3">Match History Details</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <Table sx={{ borderCollapse: "collapse" }}>
          {/* <TableHead>
        <TableRow>
          <TableCell>Column 1</TableCell>
          <TableCell>Column 2</TableCell>
        </TableRow>
      </TableHead> */}
          <TableBody>
            {/* 1 */}
            <TableRow>
              <TableCell sx={{ borderBottom: "none" }}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  alt="A"
                  src={matchData.teamA_image}
                />
              </TableCell>

              <TableCell>
                {" "}
                <h2>
                  {" "}
                  {matchData.teamA_total_score} - {matchData.teamA_total_point}{" "}
                </h2>{" "}
              </TableCell>

              <TableCell> </TableCell>

              <TableCell>
                {" "}
                <h2>
                  {" "}
                  {matchData.teamB_total_score} - {matchData.teamB_total_point}{" "}
                </h2>{" "}
              </TableCell>

              {/* <TableCell sx={{ textAlign: "center" }}> */}
              <TableCell sx={{ borderBottom: "none" }}>
                <span>
                  <Avatar
                    sx={{ width: 56, height: 56 }}
                    alt="B"
                    src={matchData.teamB_image}
                  />
                </span>
              </TableCell>
            </TableRow>
            {/* 2 */}
            <TableRow>
              <TableCell sx={{ borderBottom: "none" }}>
                <h3> {matchData.teamA_name} </h3>
              </TableCell>

              <TableCell sx={{ borderBottom: "none" }}> </TableCell>

              <TableCell sx={{ borderBottom: "none" }}>
                {" "}
                <h3> FT </h3>{" "}
              </TableCell>

              <TableCell sx={{ borderBottom: "none" }}> </TableCell>

              <TableCell sx={{ borderBottom: "none" }}>
                <h3> {matchData.teamB_name} </h3>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>

      {/*  */}

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChangeValue}
            aria-label="lab API tabs"
            centered
          >
            <Tab label="Info" value="1" />
            <Tab label="Summary" value="2" />
            <Tab label="Players" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <div className="container">
              <Card
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 1,
                  width: "100%",
                  p: 4,
                }}
              >
                {tableInfoData.map((row, index) => (
                  <Table key={index} sx={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell> {row.title} </TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: "80px" }}>
                          <Avatar
                            sx={{
                              borderRadius: "0px",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="D"
                            src={getImagesByUsingNumbers(row.image_1)}
                          />
                        </TableCell>

                        <TableCell sx={{ textAlign: "left" }}>
                          <h5>{row.text}</h5>
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </Card>
            </div>
          </div>
        </TabPanel>

        <TabPanel value="2">
          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              width: "100%",
              p: 4,
            }}
          >
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Mins</TableCell>
                  <TableCell>Player 1</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Player 2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Use the map() function to generate table rows */}
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.mins}</TableCell>
                    <TableCell>
                      {row.subsitute_player !== "" ? (
                        <>
                          <Box sx={{ fontSize: "10px" }}>
                            {row.subsitute_player}
                          </Box>
                          <Box sx={{ fontWeight: "600" }}>{row.player1}</Box>
                        </>
                      ) : (
                        <Box sx={{ fontWeight: "normal" }}>{row.player1}</Box>
                      )}
                    </TableCell>
                    {/* image 1 */}
                    <TableCell>
                      {row.image_1 > 0 ? (
                        <Avatar
                          // sx={{ width: 24, height: 24 }}
                          alt="A"
                          src={getImagesByUsingNumbers(row.image_1)}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell>
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: "none", padding: "0px 0px" }}
                        >
                          {row.score1}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", padding: "0px 20px" }}
                        >
                          {row.score2}
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    {/* image 2 */}
                    <TableCell>
                      {row.image_2 > 0 ? (
                        <Avatar
                          // sx={{ width: 20, height: 20 }}
                          alt="A"
                          src={getImagesByUsingNumbers(row.image_2)}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell>
                      {row.subsitute_player2 !== "" ? (
                        <>
                          <Box sx={{ fontSize: "10px" }}>
                            {row.subsitute_player2}
                          </Box>
                          <Box sx={{ fontWeight: "600" }}>{row.player2}</Box>
                        </>
                      ) : (
                        <Box sx={{ fontWeight: "normal" }}>{row.player2}</Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </TabPanel>
        <TabPanel value="3">
          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              width: "100%",
              p: 4,
            }}
          >
            <div className="row">
              <div className="col-6">
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{ width: 40, height: 40, marginRight: 2 }}
                          alt="A"
                          src={matchData.teamA_image}
                        />
                        <h5>{matchData.teamA_name}</h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Use the map() function to generate table rows */}
                    {matchData.teamA_players.map((player, index) => (
                      <TableRow key={index}>
                        {/* image 1 */}
                        <TableCell
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            sx={{ width: 40, height: 40, marginRight: 2 }}
                            alt="A"
                            src={s3baseUrl + player.image}
                          />

                          <h5>
                            {player.first_name} {player.last_name}
                          </h5>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="col-6">
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{ width: 40, height: 40, marginRight: 2 }}
                          alt="B"
                          src={matchData.teamB_image}
                        />
                        <h5>{matchData.teamB_name}</h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Use the map() function to generate table rows */}
                    {matchData.teamB_players.map((player, index) => (
                      <TableRow key={index}>
                        {/* image 2 */}
                        <TableCell
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            sx={{ width: 40, height: 40, marginRight: 2 }}
                            alt="B"
                            src={s3baseUrl + player.image}
                          />

                          <h5>
                            {player.first_name} {player.last_name}
                          </h5>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Card>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default MatchesHistoryDetails;
