import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_all_teams= async (data) => {
    let requestObj = {
      path: `api/teams/all_teams?page=0&limit=0`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _get_teams_by_game_id= async (id) => {
    let requestObj = {
      path: `api/teams/get_teams_by_game_id/${id}?page=0&limit=0`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
       },
    };
    return invokeApi(requestObj);
  };

  export const _get_team_listing_by_clubs= async (data) => {
 
    const requestObj = {
      path: `api/teams/get_team_listing_by_clubs`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        //  "Content-Type": "multipart/form-data",
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  // export const _get_teams_by_game_id= async (id) => {
  //   let requestObj = {
  //     path: `api/teams/get_teams_by_game_id/${id}`,
  //     method: "GET",
  //     headers: {
  //       "x-sh-auth": localStorage.getItem("token"),
  //      },
  //   };
  //   return invokeApi(requestObj);
  // };

export const _add_team= async (data) => {
 
  const requestObj = {
    path: `api/teams/add_team`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_team= async (id) => {
  const requestObj = {
    path: `api/teams/delete_team/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
  };
  return invokeApi(requestObj);
};
export const _update_team = async (postData, _id) => {
  const requestObj = {
    path: `api/teams/update_team/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};


export const _detail_team= async (_id) => {
  let requestObj = {
    path: `api/teams/detail_team/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
