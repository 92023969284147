import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _add_admin } from "../../DAL/admin/admin";
import { _add_game, _get_all_games } from "../../DAL/game/game";
import { avatar_base_url, countries, s3baseUrl } from "../../config/config";
import { _add_team } from "../../DAL/teams/teams";
import { DateTimePicker } from "@mui/x-date-pickers";
import { _add_player } from "../../DAL/players/players";
import { _get_list_club } from "../../DAL/clubs/clubs";

const Input = styled("input")({
  display: "none",
});
function AddPlayer() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allClubs, setAllClubs] = useState([]);

  const [inputs, setInputs] = useState({
    name: "",
    game_id: "",
    club_id: "",
    country: "Ireland",
    status: true,

    first_name: "",
    last_name: "",
    gender: "male",
    age: "",
    // dob: "",
    // height: "",
    // weight: "",
    // disability: "No",
    // skill: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, dob: formattedDate }));
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";
    const formData = new FormData();
    formData.append("first_name", capitalizeFirstLetter(inputs.first_name));
    formData.append("last_name", inputs.last_name);
    formData.append("gender", inputs.gender);
    formData.append("age", inputs.age);
    // formData.append("dob", inputs.dob);
    // formData.append("height", inputs.height);
    // formData.append("weight", inputs.weight);
    // formData.append("disability", inputs.disability);
    formData.append("country", inputs.country);
    // formData.append("skill", inputs.skill);
    formData.append("game", inputs.game_id);
    formData.append("status", inputs.status);
    formData.append("club", inputs.club_id);

    if (inputs.first_name.trim() === "") {
      error = "Please enter the first name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.first_name.length < 3) {
      error = "Please enter at least 3 characters for the first name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.last_name.trim() === "") {
      error = "Please enter the last name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.last_name.length < 3) {
      error = "Please enter at least 3 characters for the last name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.age.trim() === "") {
      error = "Age is required";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.age < 10 || inputs.age > 100) {
      error = "Please enter a valid age between 10 and 100";
      enqueueSnackbar(error, { variant: "error" });
    }
    //  else if (inputs.skill.trim() === "") {
    //   error = "Please enter the skill";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // else if (inputs.disability.trim() === "") {
    //   error = "Please enter the disability";
    //   enqueueSnackbar(error, { variant: "error" });
    // }
    // else if (inputs.dob.trim() == "") {
    //   error = "Please! select your date of birth";
    //   enqueueSnackbar(error, { variant: "error" });
    // }
    else {
      error = "";
      if (image && error == "") {
        if (image.type.startsWith("image/")) {
          formData.append("image", image);

          setIsLoading(true);
          const result = await _add_player(formData);

          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
      }
    }
  };

  const getAllgames = async () => {
    setIsLoading(true);
    const response = await _get_all_games();

    if (response.code === 200) {
      const GameData = response.games.map((game, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,
        };
      });

      setAllGames(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAllClubs = async () => {
    const response = await _get_list_club({
      // "text":"zzz",
      approval_status: true,
    });

    console.log("response _get_list_club", response);

    if (response.code === 200) {
      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };

        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string

        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
        };
      });

      setAllClubs(ClubData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // setIsLoading(true);
    getAllClubs();
    getAllgames();
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Player</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Sports*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="game_id"
                  value={inputs.game_id}
                  required
                  label="Sports"
                  onChange={handleChange}
                >
                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.title.charAt(0).toUpperCase() + game.title.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Country*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="country"
                  value={inputs.country || "Ireland"} // Set the default value using the value prop
                  required
                  label="Country"
                  onChange={handleChange}
                >
                  {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country.charAt(0).toUpperCase() + country.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Skill"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="skill"
                value={inputs?.skill}
                onChange={handleChange}
              />
            </div> */}

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Age"
                variant="outlined"
                sx={{ width: "100%" }}
                name="age"
                type="number"
                value={inputs?.age}
                onChange={handleChange}
              />
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth *"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date_of_birth"
                  value={inputs?.date_of_birth}
                  onChange={handleDateChange}
                  maxDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />} // Assuming TextField is imported and available
                />
              </LocalizationProvider>
            </div> */}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  value={inputs.gender}
                  label="gender"
                  onChange={handleChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Height"
                variant="outlined"
                sx={{ width: "100%" }}
                name="height"
                type="number"
                value={inputs?.height}
                onChange={handleChange}
              />
            </div> */}
            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Weight"
                variant="outlined"
                sx={{ width: "100%" }}
                name="weight"
                type="number"
                value={inputs?.weight}
                onChange={handleChange}
              />
            </div> */}

            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Disability"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="disability"
                value={inputs?.disability}
                onChange={handleChange}
              />
            </div> */}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Clubs*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="club_id"
                  value={inputs.club_id}
                  required
                  label="Clubs"
                  onChange={handleChange}
                >
                  {allClubs.map((club) => (
                    <MenuItem key={club._id} value={club._id}>
                      {club.club_name.charAt(0).toUpperCase() +
                        club.club_name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddPlayer;
