import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  IconButton,
  Card,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Badge,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { avatar_base_url, s3baseUrl } from "../../config/config";
import {
  _main_league_detail,
  _update_main_league,
} from "../../DAL/leagues/leagues";
import { _all_app_admins } from "../../DAL/admin/admin";
import { _get_detail_club, _update_edit_club } from "../../DAL/clubs/clubs";
import { _get_active_games } from "../../DAL/game/game";

const Input = styled("input")({
  display: "none",
});
function EditClub() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [imageAdmin, setImageAdmin] = useState();
  const [previews, setPreviews] = useState();
  const [previewsAdmin, setPreviewsAdmin] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [inputs, setInputs] = useState({
    club_name: "",
    game_id: [], // Initialize as an empty array
    admin: [],
    status: true,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
  });

  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };
  const fileChangedAdminHandler = (event) => {
    setImageAdmin(event.target.files[0]);
    setPreviewsAdmin(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   let value = event.target.value;

  //   if (!Array.isArray(value)) {
  //     // If the value is not an array, convert it to an array with a single element
  //     value = [value];
  //   }

  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  const handle_format_data = (data) => {
    setInputs({
      ...data,
    });
    setPreviews(data.image.src);
    setPreviewsAdmin(data.image_admin.src);
  };
  const get_detail_club = async () => {
    setIsLoading(true);
    const response = await _get_detail_club(_ID);

    console.log("response _get_detail_club", response);

    if (response.code === 200) {
      let ClubData = response.club;

      let newClubData = {
        ...ClubData,
        image: {
          src: s3baseUrl + ClubData.image,
          alt: ClubData.image,
        },

        image_admin: {
          src: s3baseUrl + ClubData.user.admin_image,
          alt: ClubData.user.admin_image,
        },
        // game_id:[],
        game_id: response.club.game.map((game) => game._id), // Set all the _id values from the game array

        first_name: ClubData.user.first_name,
        last_name: ClubData.user.last_name,
        contact_number: ClubData.user.contact_number,
      };

      handle_format_data(newClubData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("club_name", capitalizeFirstLetter(inputs.club_name));
    formData.append("status", inputs.status);
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("contact_number", inputs.contact_number);
    formData.append("game_id", JSON.stringify(inputs.game_id));

    if (inputs.club_name.trim() === "") {
      error = "Please! Enter Club Name";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";

      // imageAdmin

      if (image && imageAdmin && error === "") {
        if (
          image.type.startsWith("image/") &&
          imageAdmin.type.startsWith("image/")
        ) {
          formData.append("image", image);
          formData.append("admin_image", imageAdmin);
          setIsLoading(true);
          const result = await _update_edit_club(formData, _ID);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else if (image && error === "") {
        if (image.type.startsWith("image/")) {
          formData.append("image", image);
          setIsLoading(true);
          const result = await _update_edit_club(formData, _ID);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else if (imageAdmin && error === "") {
        if (imageAdmin.type.startsWith("image/")) {
          formData.append("admin_image", imageAdmin);
          setIsLoading(true);
          const result = await _update_edit_club(formData, _ID);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(true);
        const result = await _update_edit_club(formData, _ID);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const getActivegames = async () => {
    setIsLoading(true);
    const response = await _get_active_games();
    if (response.code === 200) {
      // let GameData = [];
      // for (let i = 0; i < 100; i++) {
      //     GameData = GameData.concat(response.game);
      //   }

      //   GameData = GameData.map((game, index) => {
      //     let profileImage = {};

      const GameData = response.game.map((game, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + game.image,
          alt: game.image,
        };

        return {
          ...game,
          image: profileImage,
        };
      });

      console.log("GameData ", GameData);
      setAllGames(GameData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_all_app_admins = async () => {
    setIsLoading(true);
    const response = await _all_app_admins({
      approval_status: true,
    });

    if (response.code === 200) {
      setAllAdmins(response.Admins);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (!state) {
    //   get_detail_club();
    // } else {
    //   handle_format_data(state);
    //   window.history.replaceState({}, document.title);
    // }
    getActivegames();
    get_detail_club();
    // get_all_app_admins();
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Club</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-center pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-button-file"
                        src={
                          previews ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>

                <div className="col-6 text-center pt-1">
                  <h3>Admin Image</h3>
                  <label htmlFor="contained-admin-button-file">
                    <Input
                      accept="image/*"
                      id="contained-admin-button-file"
                      multiple
                      type="file"
                      name="imageAdmin"
                      onChange={fileChangedAdminHandler}
                    />

                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span>
                        </>
                      }
                    >
                      <Avatar
                        id="contained-admin-button-file"
                        src={
                          previewsAdmin ||
                          `${avatar_base_url}uploads/assets/profile-icon.png`
                        }
                        className="avatar"
                        alt="Avatar"
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px solid #dbdbdb",
                        }} // Adjust the size as desired
                      />
                    </Badge>
                  </label>
                  <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Club Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="club_name"
                value={inputs?.club_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Sports*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="game_id"
                  value={inputs.game_id}
                  required
                  label="Sports"
                  onChange={handleChange}
                  multiple // Enable multi-selection
                >
                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.title.charAt(0).toUpperCase() + game.title.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Admin First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Admin Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </div> */}
            {/* <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_4"
                label="Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </div> */}

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_01"
                label="Admin Contact Number"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="contact_number"
                value={inputs?.contact_number}
                onChange={handleChange}
                type="number"
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Admins*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="admin"
                  value={inputs.admin}
                  required
                  label="Admins"
                  multiple // Enable multi-selection
                  onChange={handleChange}
                >
                  {allAdmins.map((admin) => (
                    <MenuItem key={admin._id} value={admin._id}>
                      {`${admin.first_name}  ${admin.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditClub;
