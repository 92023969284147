import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _add_admin } from "../../DAL/admin/admin";

const Input = styled("input")({
  display: "none",
});
function AddUser() {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    status: true,
    address: "",
    email: "",
    password: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let error = "";
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("status", inputs.status);

    formData.append("email", inputs.email);

    formData.append("password", inputs.password);

    if (previews) {
      formData.append("profile_image", image);
    }

    if (inputs.first_name.trim() === "") {
      error = "Please enter the First Name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.last_name.trim() === "") {
      error = "Please enter the Last Name";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.email.trim() === "") {
      error = "Please enter the Email";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.password.trim() === "") {
      error = "Please enter the Password";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      error = "";

      setIsLoading(true);
      const result = await _add_admin(formData);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }

  };

  const handleBack = () => {
    navigate(-1);
  };

  //===================================================================

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Admin User</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            {/* <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                  mt: 0,
                }}>
                <h5>Personal Info</h5>
              </Divider>
            </div> */}
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_4"
                label="Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </div>

            {/* <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <p className="">Profile Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  <img src={previews} height={50} />
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span">
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddUser;
