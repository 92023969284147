import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
// import { get_root_value } from "src/utils/domUtils";
// import { get_root_value } from "src/utils/domUtils";

function CustomConfirmation({ open, setOpen, handleAgree, title,cautionaryText }) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}>
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogContent>
        <p>{cautionaryText ? cautionaryText : ""}</p>
      </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAgree} color="error">Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
