import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { _teams_listing_by_division_id } from "../../DAL/leagues/leagues";
import { Link } from "@material-ui/core";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "team_name", label: "TEAMS", alignRight: false },
  { id: "team_image", label: "IMAGE", alignRight: false, type: "thumbnail" },
  { id: "players", label: "PLAYERS", alignRight: false },
  { id: "team_country", label: "COUNTRY", alignRight: false },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const LeagueTeamsListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allTeams, setallTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const param = useParams();
  const _ID = param.id;
  const urlParams = new URLSearchParams(window.location.search);
  const gameId = urlParams.get("game_id");

  let league_name = "League";
  let league_id = "";
  let division_name = "Division";

  if (urlParams.get("league_name") != null) {
    league_name = urlParams.get("league_name");
  }
  if (urlParams.get("division_name") != null) {
    division_name = urlParams.get("division_name");
  }
  if (urlParams.get("league_id") != null) {
    league_id = urlParams.get("league_id");
  }
  const handleClickBreadcrumbs = (event) => {
    event.preventDefault();
    const clickedElement = event.target.textContent;
    if (clickedElement === "Leagues") {
      navigate("/leagues/");
    } else if (clickedElement === league_name) {
      navigate(
        `/leagues/league-listing/${league_id}?league_name=${league_name}&game_id=${gameId}`
      );
    }
  };

  const handlePlayers = (value) => {
    navigate(
      `/leagues/players-listing/${_ID}?game_Id=${gameId}&teamId=${value.team._id}&league_id=${league_id}&league_name=${league_name}&division_name=${division_name}&team_name=${value.team_name}`,
      {
        state: value,
      }
    );
  };

  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const MENU_OPTIONS = [
    {
      label: "Players",
      icon: "ant-design:team-outlined",
      handleClick: handlePlayers,
    },
  ];

  const getAllTeams = async () => {
    setIsLoading(true);
    const response = await _teams_listing_by_division_id(_ID);
    if (response.code === 200) {
      const teamData = response.teams_of_division.map((team, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + team.team.image,
          alt: team.team.image,
        };

        return {
          ...team,
          players: team.players,
          team_name: team.team.name,
          team_country: team.team.country,
          team_image: profileImage,
        };
      });

      setallTeams(teamData);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Teams"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-12 col-sm-12 mt-3">
          <div role="presentation" onClick={handleClickBreadcrumbs}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component="button">
                Leagues
              </Link>
              <Link underline="hover" color="inherit" component="button">
                {league_name}
              </Link>
              <Typography color="text.primary">{division_name}</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Teams</h3>
        </div>
        <div className="col-lg-4 col-sm-12 text-end">
          {/* <button className="small-contained-button" onClick={handleNavigate}>
            Add New Team
          </button> */}
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allTeams}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default LeagueTeamsListing;
