import { useState } from "react";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// import { useSnackbar } from "notistack";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { Button } from "@mui/material";
// import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export default function ChangePassword({
  onhandleNewPasswordSubmit,
  setIsLoading,
  isLoading,
}) {
  // const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!newPassword) {
      return;
    }
    if (!confirmPassword) {
      return;
    }
    // if (newPassword !== confirmPassword) {
    //   enqueueSnackbar("Password and Confirm Password does not match", {
    //     variant: "error",
    //   });
    //   return;
    // }

    onhandleNewPasswordSubmit(newPassword, confirmPassword);
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}>
        <Button fullWidth size="large" type="submit" variant="contained">
          Update
        </Button>
      </Stack>
    </form>
  );
}
