import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _add_admin,
  _reset_password,
  _update_app_admin,
} from "../../DAL/admin/admin";
import { _app_api_change_password } from "../../DAL/login/login";

const Input = styled("input")({
  display: "none",
});
function ChangeOldPassword() {
  const navigate = useNavigate();
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handle_format_data = (data) => {
    setInputs({
      ...data,
    });
    setPreviews(data.profile_image.src);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    // setIsLoading(true);
    const formData = new FormData();

    formData.append("old_password", inputs.old_password);
    formData.append("new_password", inputs.new_password);
    formData.append("confirm_password", inputs.confirm_password);

    // old_password: "",
    // new_password: "",
    // confirm_password: "",
    if (inputs.old_password.trim() === "") {
      error = "Please! Enter Old Password";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.new_password.trim() === "") {
      error = "Please! Enter New Password";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.confirm_password.trim() === "") {
      error = "Please! Enter confirm Password";
      enqueueSnackbar(error, { variant: "error" });
    } else if (inputs.new_password != inputs.confirm_password) {
      error = "New Password and Confirm Password Must be same";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      setIsLoading(true);
      const result = await _app_api_change_password(formData);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (!state) {
    //   //   get_detail_product();
    // } else {
    //   console.log("state", state);
    //   handle_format_data(state);
    //   //   window.history.replaceState({}, document.title);
    // }
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">CHANGE PASSWORD</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Old Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="old_password"
                value={inputs?.old_password}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="New Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="new_password"
                value={inputs?.new_password}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Confirm Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="confirm_password"
                value={inputs?.confirm_password}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default ChangeOldPassword;
