import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
// import CustomMUITable from "../../components/GeneralComponents/CustomMUITable";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { _all_app_admins, _delete_app_admin } from "../../DAL/admin/admin";
import { s3baseUrl } from "../../config/config";
import { CircularProgress, DialogContent } from "@mui/material";
import { useSnackbar } from 'notistack';
import { _delete_club, _get_list_club } from "../../DAL/clubs/clubs";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "club_name", label: "CLUBS", alignRight: false },
  { id: "image", label: "IMAGE", alignRight: false, type: "thumbnail" },
  { id: "gameTitle", label: "SPORTS", alignRight: false },
  { id: "managers_count", label: "MANAGERS", alignRight: false },
  { id: "status", label: "STATUS", alignRight: false, type: "row_status" },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const ApprovedClubListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleEdit = (value) => {
    navigate(`/clubs/edit-club/${value._id}`, {
      state: value,
    });
  };

  const handleManagers = (value) => {
    console.log('value',value);
    navigate(`/clubs/managers/${value._id}?club_name=${value.club_name}`, {
      state: value,
    });

  };


  const handleChangePassword = (value) => {
    console.log('value',value);
    navigate(`/clubs/change-password/${value.user.email}`, {
        state: value,
      });


  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenDrawer = () => {
    // setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
      navigate(`/clubs/add-club`);
  };
  
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    {
        label: "Change Password",
        icon: "mdi:password-outline",
        handleClick: handleChangePassword,
      },

      {
        label: "Managers",
        icon: "ph:users-three-fill",
        handleClick: handleManagers,
      },
  ];
  const getAllAdmins = async () => {
    // const response = await _get_list_club({
    //   approval_status: false,
    // });
    setIsLoading(true);
    const response = await _get_list_club(

      {
        // "text":"zzz",
        'approval_status':true
    }
    );

    console.log('response _get_list_club' ,response);

    if (response.code === 200) {

      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};
      
        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };
      
        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string
      
        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
          managers_count: club.managers.length

        };
      });
 
      // fruits.reverse();
      setAllAdmins(ClubData.reverse());
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    // setIsLoading(true);
    const result = await _delete_club(deleteValue);

    if (result.code === 200) {
      let new_admins_list = allAdmins.filter((admin) => {
        return admin._id !== deleteValue;
      });

    //   setAllAdmins
      setAllAdmins(new_admins_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    getAllAdmins();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
        <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title="Are you sure you want to delete this Club?"
        handleAgree={handleDelete}
        cautionaryText="⚠️ If you want to delete this club, kindly make sure that the club contains no league. If you delete this club, the related teams and the players will also be deleted."
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Approved Clubs"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Approved Clubs</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add New Club
          </button>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allAdmins}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default ApprovedClubListing;
