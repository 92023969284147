import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
import EmailVerification from "../../components/forgotPassword/EmailVerification";
import PincodeVerification from "../../components/forgotPassword/PincodeVerification";
import ChangePassword from "../../components/forgotPassword/ChangePassword";
import { login } from "../../assets";

// ----------------------------------------------------------------------

// const RootStyle = styled(Page)(({ theme }) => ({
//   [theme.breakpoints.up("md")]: {
//     display: "flex",
//   },
// }));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  // const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(0);
  // const [adminEmail, setAdminEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const content_data = get_page_content_localStorage();

  const handleSubmitEmail = () => {
    // setAdminEmail(_email);
    // const formData = new FormData();
    // formData.append("email", _email);
    // const result = await confirmEmail(formData);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setIsLoading(false);
    setStatus(1);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleSubmitPinCode = () => {
    // const formData = new FormData();
    // formData.append("email", adminEmail);
    // formData.append("verification_code", pinCode);
    // const result = await confirmPinCode(formData);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setIsLoading(false);
    setStatus(2);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleSubmitNewPassword = () => {
    // const formData = new FormData();
    // formData.append("email", adminEmail);
    // formData.append("password", newPassword);
    // formData.append("confirm_password", confirmPassword);
    // const result = await updatePassword(formData);
    // if (result.code === 200) {
    setStatus(0);
    //   navigate("/login");
    //   enqueueSnackbar("Password Changed Successfully", { variant: "success" });
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };
  return (
    <div>
      <HeaderStyle>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      </HeaderStyle>

      <div className="row w-100">
        <div className="col-sm-5 pt-5">
          <Container>
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome Back
              </Typography>
              <img src={login} alt="Login" />
            </SectionStyle>
          </Container>
        </div>
        <div className="col-sm-7 pt-5">
          <Container>
            <ContentStyle>
              <Stack sx={{ mb: 2, pt: 2 }}>
                {status === 0 ? (
                  <>
                    <Typography variant="h4" gutterBottom>
                      Forget Password
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Enter your Email.
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {status === 1 ? (
                  <>
                    <Typography variant="h4" gutterBottom>
                      Please Check your Email
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Enter PIN Code here.
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {status === 2 ? (
                  <>
                    <Typography variant="h4" gutterBottom>
                      Forget Password
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Enter your New Password.
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Stack>
              {status === 0 ? (
                <EmailVerification
                  onhandleEmailSubmit={handleSubmitEmail}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              ) : (
                ""
              )}
              {status === 1 ? (
                <PincodeVerification
                  onhandlePinCodeSubmit={handleSubmitPinCode}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              ) : (
                ""
              )}
              {status === 2 ? (
                <ChangePassword
                  onhandleNewPasswordSubmit={handleSubmitNewPassword}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              ) : (
                ""
              )}
              <Container width="smUp">
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Back to Login?&nbsp;
                  <Link variant="subtitle2" component={RouterLink} to="/login">
                    Login
                  </Link>
                </Typography>
              </Container>
            </ContentStyle>
          </Container>
        </div>
      </div>
    </div>
  );
}
