import { invokeApi } from "../../bl_libs/invokeApi";


// club
// export const _get_list_club= async (_id) => {
//     let requestObj = {
//       path: `api/club/list_club?page=0&limit=0`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };


  export const _get_list_club= async (data) => {
 
    const requestObj = {
      path: `api/club/all_clubs`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        //  "Content-Type": "multipart/form-data",
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_club= async (data) => {
 
    const requestObj = {
      // path: `api/club/add_club`,
      path: `api/club/add_club_by_super_admin`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
         "Content-Type": "multipart/form-data",
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_club= async (id) => {
    const requestObj = {
      path: `api/club/delete_club/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
         
      },
    };
    return invokeApi(requestObj);
  };


  export const _get_detail_club= async (id) => {
    let requestObj = {
      path: `api/club/detail_club/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _update_edit_club = async (postData, _id) => {
    const requestObj = {
      path: `api/club/edit_club/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
         
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };

  export const _club_approval= async (id) => {
    let requestObj = {
      path: `api/club/club_approval/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _club_managers= async (id) => {
    let requestObj = {
      path: `api/club/club_managers/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _club_active_managers= async (id) => {
    let requestObj = {
      path: `api/club/club_active_managers/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };