import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
// import CustomMUITable from "../../components/GeneralComponents/CustomMUITable";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { _all_app_admins, _delete_app_admin } from "../../DAL/admin/admin";
import { s3baseUrl } from "../../config/config";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from 'notistack';

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "first_name", label: "FIRST NAME", alignRight: false },
  { id: "last_name", label: "LAST NAME", alignRight: false },
  {
    id: "profile_image",
    label: "PROFILE IMAGE",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "email", label: "EMAIL", alignRight: false },
  { id: "createdAt", label: "REGISTRATION DATE", alignRight: false },
  { id: "status", label: "STATUS", alignRight: false, type: "row_status" },
  { id: "action", label: "ACTION", alignRight: false, type: "action" },
];

const ApprovedUserListing = () => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleEdit = (value) => {
    navigate(`/admins/edit-user/${value._id}`, {
      state: value,
    });
  };
  
  const handleChangePassword = (value) => {
    navigate(`/admins/change-password/${value.email}`, {
        state: value,
      });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenDrawer = () => {
    // setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
      navigate(`/admins/add-user`);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    {
        label: "Change Password",
        icon: "mdi:password-outline",
        handleClick: handleChangePassword,
      },
  ];

  const getAllAdmins = async () => {
    const response = await _all_app_admins({
      approval_status: true,
    });

    if (response.code === 200) {
      const AdminData = response.Admins.map((Admin, index) => {
        let profileImage = {};
        let dateStr = Admin.createdAt;
        let date = new Date(dateStr);
        let day = date.getDate();
        let month = date.getMonth() + 1; // Adding 1 to get the correct month (January is 0-based)
        let year = date.getFullYear();
        let formattedDate = `${day < 10 ? "0" + day : day}-${
          month < 10 ? "0" + month : month
        }-${year}`;

        if (Admin.profile_image === "picture") {
          profileImage = {
            src: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
            alt: "https://livescore.dynamitelifestyle.co.uk/uploads/assets/profile-icon.png",
          };
        } else {
          profileImage = {
            src: s3baseUrl + Admin.profile_image,
            alt: Admin.profile_image,
          };
        }

        return {
          ...Admin,
          profile_image: profileImage,
          createdAt: formattedDate,
        };
      });

      setAllAdmins(AdminData);
      setIsLoading(false);

    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }

 
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    // setIsLoading(true);
    const result = await _delete_app_admin(deleteValue);
 

    if (result.code === 200) {
      let new_admins_list = allAdmins.filter((admin) => {
        return admin._id !== deleteValue;
      });

    //   setAllAdmins
      setAllAdmins(new_admins_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    getAllAdmins();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
        <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this User?"}
        handleAgree={handleDelete}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Approved Users"
        //   componentToPassDown={<AddEmployees/>}
      />

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Approved Users</h3>
        </div>
        <div className="col-lg-4 col-sm-12 mt-2 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add New User
          </button>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={allAdmins}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background"
      />
    </div>
  );
};

export default ApprovedUserListing;
