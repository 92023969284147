import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Avatar, Box, CircularProgress, Tooltip } from "@mui/material";
import { _match_filter_by_start_end_date } from "../../DAL/matches/matches";
import { useSnackbar } from "notistack";
import { Modal, Button } from "react-bootstrap"; // Import the Modal component
import interactionPlugin from "@fullcalendar/interaction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// import { isSameDay } from "date-fns"; // Import isSameDay function
// import './google-calendar-style.css'; // Adjust the path as needed
import "../../assets/css/google-calendar-style.css";
import { Onefootball } from "../../assets";
import { s3baseUrl } from "../../config/config";
import moment from "moment";
import WeekCalendar from "./WeekCalendar";
import DayCalendar from "./DayCalendar";
import DetailPopUpModel from "../../components/GeneralComponents/DetailPopUpModel";

import GoogleLoginAccess from "./GoogleLoginAccess";
import GoogleLoginOAuth from "./GoogleLoginOAuth";


import { GoogleOAuthProvider } from '@react-oauth/google';

const get_view_dates = (tool) => {
  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};

const Calendar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [eventsMatches, setEventsMatches] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for controlling the modal
  const [selectedEvent, setSelectedEvent] = useState(null); // State to store the selected event data
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Track the current year
  const [currentDate, setCurrentDate] = useState(get_view_dates("day"));
  const [eventDetailData, setEventDetailData] = useState([]);
  const [popupState, setPopupState] = useState(false);
  const [selectedTool, setSelectedTool] = useState(
    // userInfo.default_caleder_value
    "day"
  );

  const [showModalSecond, setShowModalSecond] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [currentYearResult, setCurrentYearResult] = useState(
    new Date().getFullYear()
  ); // Track the current year
  const [newYear, setNewYear] = useState(2024); // Track the current year

  const moment = require("moment-timezone");

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  // Define the eventClick callback function
  const handleEventClick = (eventClickInfo) => {
    // Access custom data from the event object
    const eventData = eventClickInfo.event.extendedProps;
    console.log("eventData", eventData);
    setSelectedEvent(eventData);
    setShowModal(true); // Open the modal
  };
  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const handleDatesSet2 = () => {
    console.log("work");
  };
  const handleDatesSet = (dateInfo) => {
    const currentYear = dateInfo.view.currentStart.getFullYear();
    // setCurrentYear(currentYear); // Update the current year when user navigates
    // console.log('new_Year',new_Year);
    // if(currentYear !==newYear){
    //     fetchEventsByYear(newYear); // Fetch events for the new year
    //     setCurrentYear(newYear); // Update the current year when user navigates
    // }
    // setTestYear(currentYear);

    // console.log('currentYear',currentYear);
    // console.log('oldYear',oldYear);
    // console.log('newYear',newYear);
    setCurrentYearResult(currentYear);
    // setNewYear(currentYear+1);
    // console.log('oldYear -- After',oldYear);
    // console.log('newYear -- After',newYear);
    // setCurrentYear(newYear); // Update the current year when user navigates
  };

  const formattedTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function FormatAllMatchesEvents(response) {
    const newFormatedMatchesData = response.matches.map((match, index) => {
      let titleCurrentMatch = `${match.teamA_id.name} Vs ${match.teamB_id.name}`;
      let dateCurrentMatch = formatDate(match.date);
      let locationCurrentMatch = match.location;

      return {
        ...match,
        title: titleCurrentMatch,
        date: dateCurrentMatch,
        location: locationCurrentMatch,
        date_match: match.date,
      };
    });

    // const [eventsMatches, setEventsMatches] = useState([
    //     { title: "Event 1", date: "2023-08-01", location: "Venue A" },
    //     { title: "Event 2", date: "2023-08-10", location: "Venue B" },
    //     // Add more events as needed with additional data
    //   ]);

    console.log(
      "events  eventsMatches newFormatedMatchesData",
      newFormatedMatchesData
    );
    setEventsMatches(newFormatedMatchesData);
  }

  const getAllMatchesEvents = async () => {
    setIsLoading(true);

    const response = await _match_filter_by_start_end_date({
      start_date: "2020-01-01",
      end_date: "2030-12-31",
    });

    console.log("response _match_filter_by_start_end_date", response);

    if (response.code === 200) {
      FormatAllMatchesEvents(response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchEventsByYear = async (year) => {
    // setIsLoading(true);

    const response = await _match_filter_by_start_end_date({
      start_date: `${year}-01-01`,
      end_date: `${year + 1}-12-31`,
    });
    console.log("response", response);
    if (response.code === 200) {
      FormatAllMatchesEvents(response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchEventsByDate = async (currentDateObject) => {
    //     const currentTimeZone = moment.tz.guess();
    // console.log('currentTimeZone ___currentTimeZone',currentTimeZone); // Output: "America/New_York" (example)
    console.log("selectedTool ___selectedTool", selectedTool); // Output: "America/New_York" (example)

    if (selectedTool !== "month") {
      setIsLoading(true);
    }

    let parsedStartDate = moment(currentDateObject.start_date);
    let parsedEndDate = moment(currentDateObject.end_date);

    if (selectedTool !== "month") {
      parsedEndDate = moment(currentDateObject.end_date).add(1, "day"); // Add 1 day to the end_date
    }

    const formattedStartDate = parsedStartDate.format("YYYY-MM-DD");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DD");

    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);

    const response = await _match_filter_by_start_end_date({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      // end_date: '2023-08-18',
    });

    console.log("response", response);
    if (response.code === 200) {
      FormatAllMatchesEvents(response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //   useEffect(() => {
  //     fetchEventsByYear(currentYear); // Fetch events for the initial year
  //   }, []);

  useEffect(() => {
    // getAllMatchesEvents();
    // setEventsMatches([
    //   { title: "Event 1", date: "2023-08-09", location: "Venue A" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   { title: "Event 2", date: "2023-08-09", location: "Venue B" },
    //   // Add more events as needed with additional data
    // ]);
  }, []);

  useEffect(() => {
    // console.log("currentYearResult", currentYearResult);
    // fetchEventsByYear(currentYearResult);
  }, [currentYearResult]);

  useEffect(() => {
    //     let currentNewStartDate = new Date(currentDate.start_date); // This creates a Date object representing the current date and time
    // let currentYear = currentNewStartDate.getFullYear(); // Get the year from the Date object

    // let currentYear =  Date(currentDate).getFullYear();
    // console.log("currentDate", currentDate);
    // console.log("currentNewStartDate", currentNewStartDate);
    // console.log("currentYear", currentYear);

    // setCurrentYearResult(currentYear);
    // console.log('currentDate',currentDate);

    fetchEventsByDate(currentDate);
  }, [currentDate]);

  const handleCellClick = (info) => {
    console.log("Cell clicked:", info);

    let tool = "day"
    // let providedDate = new Date("Mon Aug 07 2023 00:00:00 GMT+0500 (Pakistan Standard Time)");
    let providedDate = new Date(info.date);

    console.log('tool __tool',tool);

    let dates = {
      start_date: moment(providedDate).startOf(tool).toDate(),
      end_date: moment(providedDate).endOf(tool).toDate(),
    };

    // let dates = get_dates(tool);
    console.log("tool", tool);
    console.log("tool dates", dates);
    setCurrentDate(dates);
    setSelectedTool(tool);

    // setSelectedDate(info.dateStr);
    // // if(eventsForSelectedDate.length > 0){
    // setShowModalSecond(true);
  };
  const handleCloseModalSecond = () => {
    setShowModalSecond(false);
    setSelectedDate(null);
  };
  const renderEventContent = (eventInfo) => {
    const eventStyle = {
      color: "#990f02",
      display: "flex",
      alignItems: "center",
    };

    const imgStyle = {
      width: "20px",
      height: "20px",
      marginTop: "2px",
      marginRight: "8px",
    };

    return (
      // <div style={eventStyle}>
      //   <img
      //     src="https://livescore.dynamitelifestyle.co.uk/uploads/logo/logo.png"
      //     alt="Avatar"
      //     style={imgStyle}
      //   />
      //   {eventInfo.event.title}
      // </div>
      <div className="container d-flex align-items-center justify-content-center Calender-EventStyle ">
        <Box
          component="span"
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              bgcolor: "#2fed2f",
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 1,
              border: "3px solid #2fed2f",
            }}
          />
        </Box>
        {eventInfo.event.title}
      </div>
    );
  };

  const eventsForSelectedDate = eventsMatches.filter(
    (event) => event.date === selectedDate
  );
  // const displayedEvents = eventsForSelectedDate.slice(0, 2); // Display only the first 2 events

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    // setUserInfo((old) => {
    //   return {
    //     ...old,
    //     default_caleder_value: tool,
    //   };
    // });
    console.log('tool __tool',tool);
    let dates = get_dates(tool);
    console.log("tool", tool);
    console.log("tool dates", dates);

    setCurrentDate(dates);
    setSelectedTool(tool);
  };

  const eventDetail = (event) => {
    if (selectedTool === "month") {
      const eventData = event.event.extendedProps;
      console.log("eventData", eventData);
      setSelectedEvent(eventData);
      setEventDetailData(eventData);
    } else {
      setEventDetailData(event);
      setSelectedEvent(event);
    }
    setPopupState(true);

    console.log("eventDetail __eventDetail call", event);
    console.log("event __eventevent", event);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool === "month") {
      text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");
    } else if (selectedTool === "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value === "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .add(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    console.log("value handleChangeNextPrev", value);
    if (selectedTool === "month") {
      let classToClick = "fc-next-button";
      if (value === "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }

    hanldeChangeDates(value);
  };

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-4">
          <h2>Calendar</h2>
        </div>
        <div className="col-8">
          {selectedTool !== "month_11111" ? (
            <>
              <div className="float-end mt-1 mb-4 me-2">
                {/* <GoogleAuth /> */}
                {/* <GoogleLoginAccess /> */}
                 
                {/* <GoogleOAuthProvider clientId="232760983499-l0vobueprd9c02lb2nr0m6ln0nbp485m.apps.googleusercontent.com">
                      <GoogleLoginOAuth /> 
                  </GoogleOAuthProvider> */}
                 
              </div>
            </>
          ) : (
            ""
          )}

          {/* <GoogleLoginButton /> */}
        </div>
      </div>

      <div className="row">
        <div className="col-6 order-2 col-md-4 full-calendar-toolbar">
          <div className="btn-group toollbar-group-buttons">
            <button
              className={`small-contained-button-calender`}
              onClick={() => {
                handleChangeNextPrev("prev");
              }}
            >
              <ArrowBackIosIcon />
            </button>
            <button
              className={`small-contained-button-calender`}
              onClick={() => {
                handleChangeNextPrev("next");
              }}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0 text-center full-calendar-toolbar">
          <h2 className="event-title-color">{getCenteredText()}</h2>
        </div>
        <div className="col-6 col-md-4 order-3 text-center text-md-end">
          <div className="btn-group toollbar-group-buttons">
            {TOOL_BUTTONS.map((button,index) => {
              return (
                <button key={index}
                  className={`small-contained-button-calender ${
                    button.text === selectedTool ? "selected-button" : ""
                  }`}
                  onClick={() => {
                    handleChangeTools(button.text);
                  }}
                >
                  {button.text}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="col-md-12 full-calendar full-calendar-new-flow order-4">
        {selectedTool === "month" ? (
          <>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              initialView="dayGridMonth"
              events={eventsMatches}
              // headerToolbar={{
              //   left: "prev,next",
              //   center: "title",
              //   right: "dayGridMonth,timeGridWeek,timeGridDay",
              // }}
              headerToolbar={{
                left: "prev,next",
                center: "",
                right: "",
              }}
              // eventClick={handleEventClick}
              eventClick={eventDetail}
              eventColor="transparent"
              eventContent={renderEventContent}
              datesSet={handleDatesSet}
              dateClick={handleCellClick}
              // dayMaxEvents={3}

              // editable={true}
              // selectable={true}
              // selectMirror={true}
              // dayMaxEvents={window.screen.width > 768 ? 2 : 1}
              dayMaxEvents={window.screen.width > 768 ? 4 : 3}
              // dayMaxEvents={1}
              weekends={true}
              fixedWeekCount={false}
              // moreLinkContent={function (args) {
              //   return "+" + args.num;
              // }}
            />
          </>
        ) : selectedTool === "week" ? (
          <div className=" mt-5">
            <WeekCalendar
              events={eventsMatches}
              handleClick={eventDetail}
              currentDate={currentDate}
            />
          </div>
        ) : (
          // <h1>Week Calendar</h1>
          <div className=" mt-5">
            <DayCalendar
              events={eventsMatches}
              handleClick={eventDetail}
              currentDate={currentDate}
            />
          </div>
        )}
      </div>

      {/* Second */}

      <Modal
        show={showModalSecond && eventsForSelectedDate.length > 0}
        onHide={handleCloseModalSecond}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Events for {selectedDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {eventsForSelectedDate.length > 0 ? (
            eventsForSelectedDate.map((event, index) => (
              <div key={index}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <h5> {index + 1} : </h5>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mx: 1,
                    }}
                  >
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 1 }}
                      alt="A"
                      src={s3baseUrl + event.teamA_id.image}
                    />
                    <h5>{event.teamA_id.name}</h5>
                  </Box>

                  <h5> Vs </h5>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mx: 1,
                    }}
                  >
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 1 }}
                      alt="A"
                      src={s3baseUrl + event.teamB_id.image}
                    />
                    <h5>{event.teamB_id.name}</h5>
                  </Box>

                  <h5> ({formattedTime(event.time)})</h5>
                </Box>

                {/* {event.title} */}
              </div>
            ))
          ) : (
            <div>No events for this date.</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleCloseModalSecond}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        title={selectedEvent ? selectedEvent.title : ""}
        eventDetailData={selectedEvent}
        componentToPassDown={
          selectedEvent && (
            <div className="Event-detail-popUpModel">
              {/* Render event details here */}
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mx: 1,
                      }}
                    >
                      <Avatar
                        sx={{ width: 24, height: 24, mr: 1 }}
                        alt="A"
                        src={s3baseUrl + selectedEvent.teamA_id.image}
                      />
                      <h4>{selectedEvent.teamA_id.name}</h4>
                    </Box>

                    <h4> Vs </h4>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mx: 1,
                      }}
                    >
                      <Avatar
                        sx={{ width: 24, height: 24, mr: 1 }}
                        alt="A"
                        src={s3baseUrl + selectedEvent.teamB_id.image}
                      />
                      <h4>{selectedEvent.teamB_id.name}</h4>
                    </Box>
                  </Box>

                  {/* <h5> ({formattedTime(selectedEvent.time)})</h5> */}
                  <br />
                  <div>
                    <p>
                      <span className="event-detail-highlight">Date: </span>{" "}
                      {formatDate(selectedEvent.date_match)}
                    </p>
                    <p>
                      <span className="event-detail-highlight">Time: </span>{" "}
                      {formattedTime(selectedEvent.time)}
                    </p>

                    <p>
                      <span className="event-detail-highlight">Location: </span>{" "}
                      {selectedEvent.location}
                    </p>

                    <p>
                      <span className="event-detail-highlight">Game: </span>{" "}
                      {selectedEvent.game_id.title}
                    </p>
                    <p>
                      <span className="event-detail-highlight">
                        Match Start:{" "}
                      </span>{" "}
                      {selectedEvent.is_started ? "Yes" : "No"}
                    </p>

                    <p>
                      <span className="event-detail-highlight">
                        Match Finished:{" "}
                      </span>{" "}
                      {selectedEvent.is_finished ? "Yes" : "No"}
                    </p>
                  </div>
                </Box>

                {/* {event.title} */}
              </div>
              {/* ... other event details ... */}
            </div>
          )
        }
      />
    </div>
  );
};

export default Calendar;
