import { invokeApi } from "../../bl_libs/invokeApi";

export const _all_app_admins = async (data) => {
  let requestObj = {
    path: `api/app_admin/all_app_admins?page=0&limit=100000000000`,
    // path: `api/app_admin/all_app_admins`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
        
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _reset_password = async (data) => {
  let requestObj = {
    path: `api/admin/reset_password`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_admin = async (data) => {
 
  const requestObj = {
    // path: `api/app_admin/app_admin_added_by_super_admin`,
    path: `api/manager/manager_added_by_super_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};



export const _delete_app_admin = async (id) => {
  const requestObj = {
    path: `api/manager/delete_manager/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
  };
  return invokeApi(requestObj);
};

export const _update_app_admin = async (postData, _id) => {
  const requestObj = {
    path: `api/manager/update_manager/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _app_admin_approval = async (postData, _id) => {
  const requestObj = {
    path: `api/app_admin/app_admin_approval/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
       
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _detail_app_admin= async (_id) => {
  let requestObj = {
    path: `api/manager/detail_manager/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};














export const _admin_logout = async () => {
  let requestObj = {
    path: "api/app_api/logout",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_admin_data = async () => {
  let requestObj = {
    path: `api/admin/detail_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _change_password = async (data) => {
  let requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_admin = async (data) => {
  let requestObj = {
    path: `api/admin/edit_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
